import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rastreabilidade-convidado-menus',
  templateUrl: './convidado-menus.component.html',
  styleUrls: ['./convidado-menus.component.scss']
})
export class ConvidadoMenusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
