import { NgModule } from '@angular/core';
import { HomeBatchSearchComponent } from './components/home-batch-search/home-batch-search.component';
import { RouterModule, Routes } from '@angular/router';
import { NewRequestComponent } from './components/new-request/new-request.component';
import { ViewRequestComponent } from './components/view-request/view-request.component';



const routes: Routes = [
  {
    path: '',
    component: HomeBatchSearchComponent
  },
  {
    path: 'nova-solicitacao',
    component: NewRequestComponent
  },
  {
    path: ':id',
    component: ViewRequestComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BatchSearchRoutingModule { }
