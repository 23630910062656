import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RespApi } from 'src/app/shared/models/response';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  private route = 'access';

  constructor(private services: HttpService) { }

  getValidatePreRequest(param: string): Observable<RespApi<string>> {
    return this.services.getBy(`${this.route}/validatePreRequest`, param);
  }

}
