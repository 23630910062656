import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'rastreabilidade-social-modal-pdf',
  templateUrl: './social-modal-pdf.component.html',
  styleUrls: ['./social-modal-pdf.component.scss']
})
export class SocialModalPdfComponent implements OnInit {
  
  @Input()
  pdfSrc: string= '';
  // "file:///C:/Users/jefferson/Downloads/00078985339636999589_Certificate.pdf";
  @Input()
  title: string ='';  

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  clickOk() {
    this.bsModalRef.hide();
  }
}
