import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "modal-simples",
  templateUrl: "./modal-simples.component.html",
  styleUrls: ["./modal-simples.component.scss"],
})
export class ModalSimplesComponent implements OnInit {
  @Input() size = "md";
  @Input() labelBtnCancelar = "Cancelar";
  @Input() labelBtnSalvar = "Salvar";
  @Input() isBtn = false;
  @Input() labelAtivador: string;
  @Input() temRodape = true;
  @Input() disabled = false;
  @Input() titulo = '';
  @Input() tooltip = "";
  @Input() btnDisabled = false;

  @Output() confirmar = new EventEmitter<NgbActiveModal>();

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  open(content) {
    if (this.disabled) {
      return;
    }

    this.modalService.open(content, { size: this.size, centered: true });
  }

  onConfirmar(modal: NgbActiveModal) {
    this.confirmar.emit(modal);
  }
}
