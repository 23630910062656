import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _showHeader = new BehaviorSubject<boolean>(true);
  private _showFooter = new BehaviorSubject<boolean>(true);

  constructor() { }

  showHeader() {
    this._showHeader.next(true);
  }

  hideHeader() {
    this._showHeader.next(false);
  }

  isHeaderVisibleAsObservable() {
    return this._showHeader.asObservable();
  }

  showFooter() {
    this._showFooter.next(true);
  }

  hideFooter() {
    this._showFooter.next(false);
  }

  isFooterVisibleAsObservable() {
    return this._showFooter.asObservable();
  }
}
