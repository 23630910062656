import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import * as FileSaver from 'file-saver';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { ReportsService } from 'src/app/core/services/reports.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbDate, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalService } from 'src/app/core/services/alert-modal.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'rastreabilidade-producer-requester',
    templateUrl: './producer-requester.component.html',
    styleUrls: ['./producer-requester.component.scss']
})
export class ProducerRequesterComponent implements OnInit {

    page = 1;
    pageSize = 10;
    collectionSize = 0;
    currentPageSize = 10;
    isLoading: boolean = false;
    producersRequesters: any[] = [];
    searchedBurden: number = 0;
    isBlocked: boolean = false;
    requestersList: any;
    companiesList: any;
    producersList: any;
    associoations: any[] = [];
    bsConfig?: Partial<BsDatepickerConfig>

    formFilter = this.fb.group({
        code: [null],
        producer: [''],
        companyRequester: [null],
        requesterId: [''],
        requesterType: [null],
        requestType: ['bale'],
        requestStatus: [''],
        initPeriod: [''],
        finishPeriod: [''],
        initDateInput: [''],
        finishDateInput: [''],
        userNotifiedBlocked: [null]
    });

    constructor(
        private service: ReportsService,
        private fb: FormBuilder,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private modal: AlertModalService,
        private config: NgbTypeaheadConfig,
        private dateLocale: BsLocaleService) {
        this.dateLocale.use(localStorage.getItem('locale'))
        config.showHint = false;
    }

    ngOnInit(): void {
        this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue'})

        this.formFilter.get('initDateInput').valueChanges.subscribe(ret => {
            let date = ret != '' ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(-2)}-${('0' + ret?.getDate()).slice(-2)}` : '';
            this.formFilter.controls.initPeriod.setValue(date);
        });

        this.formFilter.get('finishDateInput').valueChanges.subscribe(ret => {
            let date = ret != '' ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(-2)}-${('0' + ret?.getDate()).slice(-2)}` : '';
            this.formFilter.controls.finishPeriod.setValue(date);
        });
        
        this.refreshPage();
    }

    refreshPage(): void {
        this.showSpinner();
        if (!this.validateDate())
            return;
        this.getAllProducerRequester();
        this.getAssociations();
    }

    getAssociations() {
        this.service.getAssociations('UserSinda/associations').subscribe(res => {
            this.associoations = res.data
        });
        return this.associoations;
    }

    clearFilter(event, inputCompany, inputProducer, inputRequester) {

        this.setValueForm(event, '', inputCompany);
        this.setValueForm(event, '', inputProducer);
        this.setValueFormRequester(event, '', inputRequester);

        this.formFilter.controls?.code?.setValue(null);
        this.formFilter.controls?.producer?.setValue('');
        this.formFilter.controls?.companyRequester?.setValue(null);
        this.formFilter.controls?.requesterId?.setValue('');
        this.formFilter.controls?.requesterType?.setValue(null);
        this.formFilter.controls?.requestType?.setValue('bale');
        this.formFilter.controls?.requestStatus?.setValue('');
        this.formFilter.controls?.initDateInput?.setValue('');
        this.formFilter.controls?.finishDateInput?.setValue('');
        this.formFilter.controls?.initPeriod?.setValue('');
        this.formFilter.controls?.finishPeriod?.setValue('');
        this.formFilter.controls?.userNotifiedBlocked?.setValue(null);

        this.refreshPage();
    }

    showSpinner() {
        this.isLoading = true;
        this.spinnerService.show();
    }

    hideSpinner() {
        this.isLoading = false;
        this.spinnerService.hide();
    }

    validateDate(): boolean {
        if (this.formFilter.controls.initPeriod.value > this.formFilter.controls.finishPeriod.value) {
            this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', `Data de início do período maior que a data fim.`);
            this.spinnerService.hide();
            return false;
        }

        return true;
    }

    getAllProducerRequester() {
        this.service.getAllProducerRequester({ ...this.formFilter.getRawValue() }, this.page, this.pageSize).subscribe(res => {
            this.producersRequesters = res.data?.reports;
            this.currentPageSize = res.data?.reports?.length
            this.collectionSize = res.data?.total
            this.searchedBurden = res.data?.totalBurdensRequest

            this.hideSpinner();
        }, errors => {

            this.toastService.showToast(
                ToastTypeEnum.DANGER,
                'Erro',
                `${errors}`
            );
        });
    }

    downloadArquivoCsv() {
        this.showSpinner();
        this.service.downloadCsvProducerRequester({ ...this.formFilter.getRawValue() }).subscribe(res => {
            if (res.success && res.data) {
                const blob = new Base64ToBlob().convertToBlob(
                    res.data,
                    'text/csv'
                );
                FileSaver.saveAs(blob, `relatorio`);
            } else {
                this.toastService.showToast(
                    ToastTypeEnum.DANGER,
                    'Erro',
                    'Erro ao baixar o certificado'
                );
            }
            this.spinnerService.hide();
        });
    }

    block(email: string) {
        this.showSpinner();
        this.service.blockUser(email).subscribe(res => {
            if (res.success) {
                this.toastService.showToast(
                    ToastTypeEnum.SUCCESS,
                    'Sucesso',
                    'Sucesso ao bloquear'
                );

                this.refreshPage();

            } else {
                this.toastService.showToast(
                    ToastTypeEnum.DANGER,
                    'Erro',
                    'Erro ao bloquear'
                );
            }
            this.spinnerService.hide();
        })
    }

    unlock(email: string) {
        this.showSpinner();
        this.service.unlockUser(email).subscribe(res => {
            if (res.success) {
                this.toastService.showToast(
                    ToastTypeEnum.SUCCESS,
                    'Sucesso',
                    'Sucesso ao desbloquear'
                );

                this.refreshPage();

            } else {
                this.toastService.showToast(
                    ToastTypeEnum.DANGER,
                    'Erro',
                    'Erro ao desbloquear'
                );
            }
            this.spinnerService.hide();
        })
    }

    notifyUser(email: string) {
        this.showSpinner();
        this.service.notifyUser(email).subscribe(res => {
            if (res.success) {
                this.toastService.showToast(
                    ToastTypeEnum.SUCCESS,
                    'Sucesso',
                    'Sucesso ao notificar o usuário'
                );

                this.refreshPage();

            } else {
                this.toastService.showToast(
                    ToastTypeEnum.DANGER,
                    'Erro',
                    'Erro ao notificar o usuário'
                );
            }
            this.spinnerService.hide();
        })
    }

    setValueForm(event: any, selectedControl: string, input: any = null) {
        switch (selectedControl) {
            case 'producer':
                this.formFilter.controls.producer.setValue(event.item.produtorId);
                break;
            case 'companyRequester':
                this.formFilter.controls.companyRequester.setValue(event.item.key)
                break;
            default:
                event.preventDefault();
                input.value = ''

        }
    }

    setValueFormRequester(event: any, selectedControl: string, input: any = null) {

        if(input == null) {
            this.formFilter.controls.requesterId.setValue(event.item.key);
        } else {
            event.preventDefault();
            input.value = '';
        }
    }

    formatterProducer(x: { nomeProdutor: string }) {
        return x.nomeProdutor
    }

    formatterCompany(x: { value: string }) {
        return x.value
    }

    formatterRequester(x: { value: string }) {
        return x.value
    }

    searchProducer = (text$: Observable<string>) =>
        text$.pipe(
            distinctUntilChanged(),
            map(term => term.length < 2 ? []
                : this.getProducer(term)
            )
        )

    getProducer(param: string) {
        if (this.formFilter.controls.code.value) {
            param = param + "?associationId=" + this.formFilter.controls.code.value;
        }
        this.service.getAssociations(`UserSinda/producer/search/${param}`).subscribe(res => {
            this.producersList = res.data
        });
        return this.producersList;
    }

    searchCompany = (text$: Observable<string>) =>
        text$.pipe(
            distinctUntilChanged(),
            map(term => term.length < 2 ? []
                : this.getCompany(term)
            )
        )

    getCompany(param: string) {
        this.service.getCompany(param).subscribe(res => {
            this.companiesList = res.data
        });
        return this.companiesList;
    }

    searchRequester = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => term.length < 1 ? []
                :  this.getRequester(term)
            )
        )

    getRequester(param: string) {
        if (this.formFilter.controls.companyRequester.value) {
            param = param + "?CompanyId=" + this.formFilter.controls.companyRequester.value;
        }
        this.service.getRequester(param).subscribe(res => {
            this.requestersList = res.data
        });
        return this.requestersList;
    }

    GenerateFileFrequency(producer: string, id: string) {
        this.showSpinner();
        var requestType = this.formFilter.controls.requestType.value;
        let param = `${producer}/${id}/${requestType}`;
        this.service.generateFileFrequencyProducer(param).subscribe(res => {
            if (res.success && res.data) {
                const blob = new Base64ToBlob().convertToBlob(
                    res.data,
                    'text/plain'
                );
                FileSaver.saveAs(blob, `File Frequency`);
            } else {
                this.toastService.showToast(
                    ToastTypeEnum.DANGER,
                    'Erro',
                    'Erro ao baixar o certificado'
                );
            }
            this.spinnerService.hide();
        });
    }

    GenerateFileSequency(producer: string, id: string) {
        this.showSpinner();
        var requestType = this.formFilter.controls.requestType.value;
        let param = `${producer}/${id}/${requestType}`;
        this.service.generateFileSequencyProducer(param).subscribe(res => {
            if (res.success && res.data) {
                const blob = new Base64ToBlob().convertToBlob(
                    res.data,
                    'text/plain'
                );
                FileSaver.saveAs(blob, `File Sequency`);
            } else {
                this.toastService.showToast(
                    ToastTypeEnum.DANGER,
                    'Erro',
                    'Erro ao baixar o certificado'
                );
            }
            this.spinnerService.hide();
        });
    }

    async showFrequency(producerType: string, producerId: number, userId: string, date: string, code: string) {

        this.showSpinner();

        this.service.getFrequency(producerType, producerId, userId, date, code)
            .subscribe(async res => {

                this.hideSpinner();

                await this.modal.showFrequencyModal(res.data.requester, res.data.request, res.data.burdens, res.data.total)
                    .pipe(
                        map((data) => {
                            if (data) {

                                this.showSpinner();

                                this.service.downloadFrequencyCsv(producerType, producerId, userId, date, code)
                                    .subscribe(res => {
                                        if (res.success && res.data) {
                                            const blob = new Base64ToBlob().convertToBlob(
                                                res.data,
                                                'text/csv'
                                            );
                                            FileSaver.saveAs(blob, `relatorio-frequencia`);
                                        } else {
                                            this.toastService.showToast(
                                                ToastTypeEnum.DANGER,
                                                'Erro',
                                                'Erro ao baixar o relatorio de frequencia'
                                            );
                                        }
                                        this.hideSpinner();
                                    })
                            }
                        })).toPromise();
            })
    }

    async showSequence(producerType: string, producerId: number, userId: string, date: string, code: string) {

        this.showSpinner();

        this.service.getSequence(producerType, producerId, userId, date, code)
            .subscribe(async res => {

                this.hideSpinner();

                await this.modal.showSequenceModal(res.data.requester, res.data.request, res.data.burdens, res.data.total)
                    .pipe(
                        map((data) => {
                            if (data) {
                                this.showSpinner();
                                this.service.downloadSequenceCsv(producerType, producerId, userId, date, code)
                                    .subscribe(res => {
                                        if (res.success && res.data) {
                                            const blob = new Base64ToBlob().convertToBlob(
                                                res.data,
                                                'text/csv'
                                            );
                                            FileSaver.saveAs(blob, `relatorio-sequencia`);
                                        } else {
                                            this.toastService.showToast(
                                                ToastTypeEnum.DANGER,
                                                'Erro',
                                                'Erro ao baixar o relatorio de frequencia'
                                            );
                                        }
                                        this.hideSpinner();
                                    })
                            }
                        })).toPromise();
            })

    }
}
