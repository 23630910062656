<div class="modal-content modal__{{ type }}">
  <div class="modal-header"></div>
  <div class="modal-body">
    <p class="message__response">
      <fa-icon [icon]="['fas', 'paper-plane']" style="color: #004f80; size: 16px;" size="lg" *ngIf="type ==='warn'">
      </fa-icon>&nbsp;
      <fa-icon [icon]="['fas', 'check-circle']" style="color: #217d5d; size: 16px;" size="lg" *ngIf="type ==='success'">
      </fa-icon>&nbsp;
      <fa-icon [icon]="['fas', 'times']" style="color: #7d2121; size: 16px;" size="lg" *ngIf="type ==='danger'">
      </fa-icon>&nbsp;
      {{ msg }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onConfirm()">{{ okTxt }}</button>
  </div>
</div>
