import {
  Component,
  Injectable,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ModalConfig } from './modal.config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
@Injectable()
export class ModalComponent implements OnInit {
  @Input() public modalConfig: ModalConfig;
  @ViewChild('modal') private modalContent: TemplateRef<ModalComponent>;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        beforeDismiss: () => {
          this.close();
          return true;
        },
        ...this.modalConfig?.options
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  async close(): Promise<void> {
    if (
      this.modalConfig.shouldClose === undefined ||
      (await this.modalConfig.shouldClose())
    ) {
      const result =
        this.modalConfig.onClose === undefined ||
        (await this.modalConfig.onClose());
      this.modalRef.close(result);
    }
  }

  async deny(): Promise<void> {
    if (
      this.modalConfig.shouldDeny === undefined ||
      (await this.modalConfig.shouldDeny())
    ) {
      const result =
        this.modalConfig.onDeny === undefined ||
        (await this.modalConfig.onDeny());
      this.modalRef.close(result);
    }
  }

  async dismiss(): Promise<void> {
    if (
      this.modalConfig.shouldDismiss === undefined ||
      (await this.modalConfig.shouldDismiss())
    ) {
      const result =
        this.modalConfig.onDismiss === undefined ||
        (await this.modalConfig.onDismiss());
      this.modalRef.dismiss(result);
    }
  }

  async save(): Promise<void> {
    if (
      this.modalConfig.shouldSave === undefined ||
      (await this.modalConfig.shouldSave())
    ) {
      const result =
        this.modalConfig.onSave === undefined ||
        (await this.modalConfig.onSave());
      this.modalRef.close(result);
    }
  }
}
