import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeMonitoringComponent } from './home-monitoring/home-monitoring.component';
import { Router, RouterModule, Routes } from '@angular/router';
import { NewMonitoringComponent } from './new-monitoring/new-monitoring.component';
import { EditMonitoringComponent } from './edit-monitoring/edit-monitoring.component';

const routes: Routes = [
  {
    path: '',
    component: HomeMonitoringComponent
  },
  {
    path: 'novo-registro',
    component: NewMonitoringComponent
  },
  {
    path: ':id/editar',
    component: EditMonitoringComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MonitoringConfigurationRoutingModule { }
