import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BaseComponent } from './base/base.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { RequesterHeaderComponent } from './header/requester-header/requester-header.component';
import { AbrapaHeaderComponent } from './header/abrapa-header/abrapa-header.component';
import { UserInfoHeaderComponent } from './header/user-info-header/user-info-header.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    BaseComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    PageNotFoundComponent,
    RequesterHeaderComponent,
    AbrapaHeaderComponent,
    UserInfoHeaderComponent,
    UnauthorizedComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    FontAwesomeModule
  ],
  exports: [
    BaseComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    PageNotFoundComponent,
    CommonModule
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `${CoreModule.name} has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
