import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AverageTimeComponent } from './accreditation/average-time/average-time.component';
import { RequestStatusComponent } from './accreditation/request-status/request-status.component';
import { ProducerRequesterComponent } from './producer-trading/producer-requester/producer-requester.component';
import { RequestDetailComponent } from './producer-trading/request-detail/request-detail.component';
import { TradingRequesterComponent } from './producer-trading/trading-requester/trading-requester.component';
import { RequesterReportComponent } from './requester/requester-report/requester-report.component';

const routes: Routes = [
  {
    path: 'produtor-solicitante',
    component: ProducerRequesterComponent,
  },
  {
    path: 'trading-solicitante',
    component: TradingRequesterComponent,
  },
  {
    path: 'produtor-solicitante/detalhes/:id',
    component: RequestDetailComponent
  },
  {
    path: 'trading-solicitante/detalhes/:id',
    component: RequestDetailComponent
  },
  {
    path: 'status-credenciamento',
    component: RequestStatusComponent
  },
  {
    path: 'tempo-medio',
    component: AverageTimeComponent
  },
  {
    path: 'solicitante',
    component: RequesterReportComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
