<ativador-tooltip [isBtn]="isBtn" [label]="labelAtivador" [disabled]="disabled" [tooltipEnabled]="tooltip.length > 0"
  [tooltipText]="tooltip" (action)="open(content)">
</ativador-tooltip>

<ng-template #content let-modal>
  <h3 *ngIf="titulo" class="titulo">{{ titulo }}</h3>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div *ngIf="temRodape" class="modal-footer">
    <button type="button" class="btn btn-light btn-md" (click)="modal.close()">{{ labelBtnCancelar }}</button>
    <button type="button" [disabled]="btnDisabled" class="btn btn-primary btn-md" (click)="onConfirmar(modal)">{{
      labelBtnSalvar }}</button>
  </div>
</ng-template>
