<p class="p-black" translate>search_by_bale.burden_data</p>
<div class="form-row">
    <div class="col-md-1">
        <label translate>
            search_by_bale.code
        </label>
    </div>
    <div class="col-md-5">
        <label>{{ baleInformation?.code }}</label>
    </div>
    <div class="col-md-2">
        <label translate>
            search_by_bale.crop_year
        </label>
    </div>
    <div class="col-md-4">
        <label>{{ baleInformation?.cropYearDto?.description }}</label>
    </div>
</div>