<div class="modal-content">
    <div class="container">
        <div class="row">
            <label translate>REPORTS.GENERIC.REQUESTER</label> : {{ requester }}
        </div>
        <div class="row">
            <label translate>REPORTS.REQUESTER.REQUEST</label> : {{ request }}
        </div>
        <div class="row">
            <label translate>reports.requester.quantity_of_bales_consulted</label> : {{ total }}
        </div>
    </div>

    <p class="p-black d-flex justify-content-center" translate>reports.requester.consultation_frequency</p>
    <div class="modal-body">
        <table class="table">
            <thead class="thead-light">
                <tr>
                    <th translate>REPORTS.GENERIC.FREQUENCY.BALE</th>
                    <th translate>REPORTS.GENERIC.FREQUENCY.NUMBER_SEARCHS</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let frequency of tableFrequency">
                    <td>{{ frequency?.code }}</td>
                    <td>{{ frequency?.frequency }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <a class="file" (click)="onDownloadCsv()" translate>
            <fa-icon [icon]="['fas', 'file-csv']"></fa-icon>&nbsp;
            generic.buttons.download_csv
        </a>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="onClose()">{{ cancelTxt }}</button>
    </div>
</div>