import { NgModule } from '@angular/core';
import { HomeManageAccessRequestComponent } from './home-manage-access-request/home-manage-access-request.component';
import { ManageRequestAccessRouting } from './manage-access-request-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditAccessRequestComponent } from './edit-access-request/edit-access-request.component';
import { HistoryComponent } from './edit-access-request/history/history.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ApprovedDisapprovedComponent } from './edit-access-request/approved-disapproved/approved-disapproved.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HistoryFormCardComponent } from './edit-access-request/history-form-card/history-form-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxMaskModule } from 'ngx-mask';
import { EditAccessRequesterComponent } from './edit-access-request/edit-access-requester/edit-access-requester.component';

@NgModule({
  declarations: [
    HomeManageAccessRequestComponent,
    EditAccessRequestComponent,
    EditAccessRequesterComponent,
    HistoryComponent,
    ApprovedDisapprovedComponent,
    HistoryFormCardComponent
  ],
  imports: [
    CommonModule,
    ManageRequestAccessRouting,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    FormsModule,
    SharedModule,
    FontAwesomeModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
  ]
})
export class ManageAccessRequestModule { }
