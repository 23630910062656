<card>
    <card-header>
        <div class="container">
            {{'burden_requests.new_request_batch_query' | translate }}
        </div>

    </card-header>
    <alert type="info">
        {{'burden_requests.messages.information_responsibility'| translate}}

    </alert>
    <div class="container">
        <card-body>
            <div class="row col-md-12 d-flex justify-content-center">

                <div class="col-md-5 border-info" (click)="downloadTemplate()">
                    <div class="d-flex">
                        <fa-icon class="move__circle" [icon]="['fas', 'circle']"
                            style="color: #F6F4F3;; font-size: 40px;">
                        </fa-icon>
                        <div>
                            <fa-icon class="download__move" [icon]="['fas', 'cloud-download-alt']"
                                style="color: #17536e; font-size: 20px;">
                            </fa-icon>
                        </div>
                        <p class="card-text" translate>
                            burden_requests.drag_and_drop_file.download_the_model
                            <fa-icon class="ajust__arrow" [icon]="['fas','angle-right']"></fa-icon>
                        </p>
                    </div>
                    <label>
                        <div class="lbl__formato" translate>
                            burden_requests.view_request.column_burden_code
                        </div>
                    </label>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5 border-info" dragdrop (fileDropped)="onFileDropped($event)">

                    <input type="file" #fileDropRef id="fileDropRef" accept="text/plain"
                        (change)="fileBrowseHandler($event)" />

                    <div class="d-flex">
                        <fa-icon class="circle__move" [icon]="['fas', 'circle']"
                            style="color: #F6F4F3;; font-size: 40px;">
                        </fa-icon>
                        <fa-icon class="upload__move" [icon]="['fas', 'upload']"
                            style="color: #17536e; font-size: 20px;">
                        </fa-icon>
                    </div>
                    <p class="card-text" translate *ngIf="files.length<=0">
                        burden_requests.drag_and_drop_file.add
                        <fa-icon class="ajust__arrow" [icon]="['fas','angle-right']"></fa-icon>
                    </p>
                    <label>
                        <label>
                            <p class="row card-text" translate *ngIf="files.length>0">
                                burden_requests.drag_and_drop_file.change
                            </p>
                        </label>
                        <label>
                            <div class="lbl__formato" translate>
                                burden_requests.view_request.accepted_format
                            </div>
                        </label>
                    </label>
                </div>
            </div>




            <div class="row d-flex align-items-center col-md-12 single-file">
                <div *ngFor="let file of files; let i = index">
                    <div>
                        <fa-icon [icon]="['fas', 'paperclip']"></fa-icon>&nbsp;&nbsp;
                        {{ file?.name }}
                        <svg (click)="deleteFile(i)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path fill-rule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                    </div>
                </div>



                <!-- <div class="single-file" *ngFor="let file of files; let i = index">
                        <div>
                            <fa-icon [icon]="['fas', 'paperclip']"></fa-icon>&nbsp;&nbsp;
                            {{ file?.name }}
                        </div>
                        <label>
                            <div (mouseover)="changeText=true" (mouseout)="changeText=false">
                                <fa-icon *ngIf="!changeText" [icon]="['fas', 'check-circle']"
                                    style="color: #0f6620; font-size: 20px; cursor: pointer">
                                </fa-icon>
                            </div>
                            <div (mouseover)="changeText=true" (mouseout)="changeText=false">
                                <fa-icon *ngIf="changeText" [icon]="['fas', 'times-circle']"
                                    style="color: #d9001b; font-size: 20px; cursor: pointer" (click)="deleteFile(i)">
                                </fa-icon>
                            </div>
                        </label>
                    </div> -->

                <div *ngIf="error || internalError" class="col alert alert-danger">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>&nbsp;
                    <span *ngIf="error" translate>burden_requests.messages.request_not_created</span>
                    <span *ngIf="internalError" translate>generic.messages.internal_error</span>
                </div>
                <!-- <a [routerLink]="['/consulta-por-lote']" class="btn btn__default" translate>generic.cancel_button</a>  -->
                <div class="d-flex justify-content-end col-md-12">
                    <button class="btn btn-primary" [disabled]="files.length<1" (click)="sendBurdenRequest()">
                        <span translate>burden_requests.send_burden_request</span>
                    </button>
                </div>

            </div>
        </card-body>
    </div>
</card>