import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast.service';
import { SpinnerService } from '../services/spinner.service';
import { AuthService } from '../services/auth.service';
import { APP_API } from 'src/app/configurations/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  [x: string]: any;

  constructor(
    private toast: ToastService,
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<import('@angular/common/http').HttpEvent<any>> {
    req = this.authService.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error) => {
        let message = '';
        this.spinnerService.hide();

        if (error.status === 0) {
          this.toast.show('O servidor está inacessível no momento.', {
            classname: `bg-danger text-light`,
            delay: 5000,
            autohide: true,
            headertext: `Server Error`,
          });
          return throwError(error.statusText);
        }

        if (error.status === 401 && req.url.includes(APP_API)) {
          message = '';
          if (this.router.routerState.snapshot.url === '/login') {
            message = 'As credenciais informadas são válidas';
          } else {
            message = 'Você não possui permissão para acessar esse conteúdo.';
          }

          this.router.navigate(['']);
        }

        if (error.status === 403 && req.url.includes(APP_API)) {
          message = '';
          if (this.router.routerState.snapshot.url === '/login') {
            message = 'Você não possui permissão para acessar esse conteúdo.';
          } else {
            message = 'Você não possui permissão para acessar esse conteúdo.';
          }

          this.router.navigate(['unauthorized']);
        }

        if (error instanceof HttpErrorResponse) {
          const applicationError = error.headers.get('Application-Error');
          if (applicationError) {
            return throwError(applicationError);
          }

          const serverError = error;

          let modalStateErrors = '';
          if (serverError?.error && typeof serverError?.error === 'object') {
            for (const key in serverError.error) {
              if (serverError.error[key]) {
                modalStateErrors += serverError.error[key] + '\n';
              }
            }
          }

          return throwError(modalStateErrors || message);
        }
      })
    );
  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
