import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from '../auth/auth.guard';
import { ContactUsComponent } from './home-search-by-bale/contact-us/contact-us.component';
import { HomeConsultByBaleComponent } from './home-search-by-bale/home-search-by-bale.component';

const routes: Routes = [
    {
        path: '',
        component: HomeConsultByBaleComponent
    }, 
    {
        path: 'contate-nos',
        component: ContactUsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ConsultByBaleRouting { }

