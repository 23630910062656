<ul class="nav mr-auto nav__menu">

    <li class="nav-item dropdown menu__itens">
        <a class="nav-link dropdown-toggle main__item" href="javascript:void(0)" data-bs-toggle="dropdown"
            translate>header.administrative</a>
        <ul class="dropdown-menu">
            <li><a class="dropdown-item sub__menu" translate>certificate_configuration.form.title</a></li>
            <li><a class="dropdown-item sub__menu" translate>monitoring.form.labels.monitoring_configuration</a></li>
        </ul>
    </li>
    <li class="nav-item dropdown menu__itens">
        <a [routerLink]="['gerenciar-pedidos-de-acesso']" class="nav-link main__item"
            translate>header.applicant_accreditation</a>
    </li>
    <li class="nav-item dropdown menu__itens">
        <a class="nav-link dropdown-toggle main__item" href="javascript:void(0)" data-bs-toggle="dropdown"
            translate>header.reports</a>
        <ul class="dropdown-menu">
            <li><a class="dropdown-item sub__menu" translate>new_request.view_request.producer_trading</a></li>
            <li><a class="dropdown-item sub__menu" translate>header.accreditation</a></li>
        </ul>
    </li>

</ul>