import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonServices } from 'src/app/core/services/common.services';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { Editor, Toolbar } from 'ngx-editor';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'rastreabilidade-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  nomenclatureEditor: Editor;
  nomenclatureLeftEditor: Editor;
  observetionEditor: Editor;
  importantInformationEditor: Editor;
  qualityProgramSBRHVIEditor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  fSubmited: boolean = false;
  configForm = this.fb.group({
    nomenclature: ['', Validators.required],
    importantInformation: ['', Validators.required],
    qualityProgramSBRHVI: ['', Validators.required],
    nomenclatureLeft: ['', Validators.required],
    observetion: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private service: HttpService,
    private toast: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.nomenclatureEditor = new Editor();
    this.nomenclatureLeftEditor = new Editor();
    this.observetionEditor = new Editor();
    this.importantInformationEditor = new Editor();
    this.qualityProgramSBRHVIEditor = new Editor();
    this.getData();
  }

  ngOnDestroy(): void {
    this.nomenclatureEditor.destroy();
    this.importantInformationEditor.destroy();
    this.qualityProgramSBRHVIEditor.destroy();
  }

  getData() {
    this.service.get(`CertificateConfiguration`).subscribe((res) => {
      this.f.nomenclature.setValue(res?.data?.nomenclature);
      this.f.importantInformation.setValue(res?.data?.importantInformation);
      this.f.qualityProgramSBRHVI.setValue(res?.data?.qualityProgramSBRHVI);
      this.f.nomenclatureLeft.setValue(res?.data?.nomenclatureLeft);
      this.f.observetion.setValue(res?.data?.observation);
    });
  }

  get f() {
    return this.configForm.controls;
  }

  onSubmit() {
    this.fSubmited = true;
    if (this.configForm.valid) {
      this.service
        .post(`CertificateConfiguration`, this.configForm.value)
        .subscribe((res) => {
          if (res?.success) {
            this.translate
              .get(`generic.messages.success_header`)
              .subscribe((header) => {
                if (res?.data) {
                  this.toast.showToast(
                    ToastTypeEnum.SUCCESS,
                    header,
                    res?.data
                  );
                } else {
                  this.translate
                    .get(`generic.messages.save_success`)
                    .subscribe((msg) => {
                      this.toast.showToast(ToastTypeEnum.SUCCESS, header, msg);
                    });
                }
              });
          } else {
            this.translate
              .get(`generic.messages.error_header`)
              .subscribe((header) => {
                if (res?.data) {
                  this.toast.showToast(ToastTypeEnum.DANGER, header, res?.data);
                } else {
                  this.translate
                    .get(`generic.messages.save_error`)
                    .subscribe((msg) => {
                      this.toast.showToast(ToastTypeEnum.DANGER, header, msg);
                    });
                }
              });
          }
          this.getData();
        });
      this.fSubmited = false;
    }
  }
}
