import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rastreabilidade-cotton-farm-origin',
    templateUrl: './cotton-farm-origin.component.html',
    styleUrls: ['./cotton-farm-origin.component.scss']
})
export class CottonFarmOriginComponent implements OnInit {

    public googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query='
    @Input() public cottonFarmOrigin: any = null;

    constructor() { }

    ngOnInit(): void {
        if (this.cottonFarmOrigin !== null) {
            this.googleMapsUrl = `${this.googleMapsUrl}${this.cottonFarmOrigin?.addressHviDto.latitude}%2C${this.cottonFarmOrigin?.addressHviDto.longitude}`
        }
    }

}
