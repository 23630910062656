import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonServices } from 'src/app/core/services/common.services';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-additional-data',
  templateUrl: './additional-data.component.html',
  styleUrls: ['./additional-data.component.scss']
})
export class AdditionalDataComponent implements OnInit {

  categories: any[] = [];
  selectedFile: File = null;
  base64File: string = '';
  fileName: string = '';
  fileExtension: string = '';
  fileSize: number = 0;
  uploadButton: string = 'INCLUIR';
  identifiedSystems: any[] = null;

  registerForm = this.fb.group({
    id: ['', Validators.required],
    name: ['', Validators.required],
    cpf_passport: ['', Validators.required],
    birth_date: ['', Validators.required],
    role: ['', Validators.required],
    corporative_telephone: ['', Validators.required],
    corporative_cellphone: ['', Validators.required],
    company_category: ['', Validators.required],
    company_name: ['', Validators.required],
    zip_code: ['', Validators.required],
    company_address: ['', Validators.required],
    city: ['', Validators.required],
    corporative_email: [{ value: null, disabled: true }, Validators.required],
    country: ['', Validators.required],
    company_website: ['', Validators.required],
    company_bond_file: [null, Validators.required],
    name_complement: ['', Validators.required],
    corporative_email_complement: ['', Validators.required],
    corporative_telephone_complement: ['', Validators.required],
    company_bond_file_name: [''],
    company_bond_file_extension: [''],
    company_bond_file_size: [''],
    locale: ['']
  })

  constructor(private route: ActivatedRoute, private services: CommonServices, private fb: FormBuilder, public toast: ToastService) { }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      let receivedParam = data['code']
      this.validatePreRequest(receivedParam);
      this.loadCategories();
    });
  }

  validatePreRequest(receivedParam: string) {
    this.services.getBy('Access/additionalData', `${receivedParam}==`).subscribe(x => {
      this.registerForm.controls.id.setValue(x.data.id);
      this.registerForm.controls.name.setValue(x.data.name);
      this.registerForm.controls.cpf_passport.setValue(x.data.cpf_passport);
      this.registerForm.controls.birth_date.setValue(x.data.birth_date);
      this.registerForm.controls.role.setValue(x.data.role);
      this.registerForm.controls.corporative_telephone.setValue(x.data.corporative_telephone);
      this.registerForm.controls.corporative_cellphone.setValue(x.data.corporative_cellphone);
      this.registerForm.controls.company_category.setValue(x.data.company_category);
      this.registerForm.controls.company_name.setValue(x.data.company_name);
      this.registerForm.controls.zip_code.setValue(x.data.zip_code);
      this.registerForm.controls.company_address.setValue(x.data.company_address);
      this.registerForm.controls.city.setValue(x.data.city);
      this.registerForm.controls.corporative_email.setValue(x.data.corporative_email);
      this.registerForm.controls.country.setValue(x.data.country);
      this.registerForm.controls.company_website.setValue(x.data.company_website);
    });
  }

  confirm() {
    this.registerForm.controls.company_bond_file.setValue(this.base64File.split(',')[1]);
    this.registerForm.controls.company_bond_file_name.setValue(this.fileName);
    this.registerForm.controls.company_bond_file_extension.setValue(this.fileExtension);
    this.registerForm.controls.company_bond_file_size.setValue(this.fileSize);
    this.registerForm.controls.locale.setValue(localStorage.getItem('locale'))
    let data = this.registerForm.getRawValue();

    this.services.putData(`Access/additionalData`, data).subscribe(x => {
      this.toast.showToast(ToastTypeEnum.INFO, 'Sucesso', "Sucesso ao salvar os dados")
    });
  }

  loadCategories() {
    this.services.get(`Company/categoryCompany?locale=${localStorage.getItem('locale')}`).subscribe(x => {
      this.categories = x.data
    })
  }

  loadSystems() {
    this.services.get(`UserSinda/userSinda`).subscribe(x => {
      this.identifiedSystems = x?.data?.applicationsAccess;
    })
  }

  async onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0]
    this.fileName = this.selectedFile.name
    this.fileSize = this.selectedFile.size
    this.fileExtension = this.selectedFile.name.substr(this.selectedFile.name.lastIndexOf('.') + 1);
    await this.fileToBase64();

    if (!this.validateExtension(this.fileExtension)) {
      this.fileName = null;
      this.registerForm.controls.company_bond_file.setValue(null);
      this.toast.showToast(ToastTypeEnum.DANGER, 'Erro', 'Formato do arquivo inválido')
    }
  }

  validateExtension(extension: string): boolean {
    if (extension === 'pdf' || extension === 'png' || extension === 'jpeg' || extension === 'jpg')
      return true;

    return false;
  }

  stringfyObject(value: any): string {
    return JSON.stringify(value);
  }

  async fileToBase64() {
    let reader = new FileReader();
    reader.readAsDataURL(this.selectedFile as Blob);
    reader.onloadend = async () => {
      this.base64File = await reader.result as string
    }
  }

}
