<div class="modal-content">
    <div class="container">
        <div class="row">
            <label translate>REPORTS.GENERIC.REQUESTER</label> : {{ requester }}
        </div>
        <div class="row">
            <label translate>REPORTS.REQUESTER.REQUEST</label> : {{ request }}
        </div>
        <div class="row">
            <label translate>reports.requester.quantity_of_bales_consulted</label> : {{ total }}
        </div>
    </div>

    <p class="p-black d-flex justify-content-center" translate>reports.requester.query_sequence</p>
    <div class="modal-body">
        <table class="table">
            <thead class="bordered-lined">
                <tr>
                    <th translate class="bordered-lined">REPORTS.GENERIC.SEQUENCE.BALE_SEQUENCE</th>
                    <th translate class="bordered-lined">REPORTS.GENERIC.SEQUENCE.IDENTIFICATION</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let frequency of tableFrequency; index as i">
                    <ng-container *ngIf="frequency.length > 0">
                        <tr *ngFor="let item of frequency; let first = first">
                            <td class="bordered-lined">{{ item }}</td>
                            <td *ngIf="first" [attr.rowspan]="frequency.length" class="bordered-lined">
                                {{ 'REPORTS.GENERIC.SEQUENCE.SEQUENCE' | translate}} {{ i + 1 }} <br>
                                ({{ frequency.length }})
                              </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <a class="file" (click)="onDownloadCsv()" translate>
            <fa-icon [icon]="['fas', 'file-csv']"></fa-icon>&nbsp;
            generic.buttons.download_csv
        </a>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="onClose()">{{ cancelTxt }}</button>
    </div>
</div>