<card>
    <card-header>
        <div class="container" translate>header.search_by_bale</div>
    </card-header>
    <br>
    <card-body>
        <div class="container">
            <form [formGroup]="searchBaleForm">
                <div class="row">
                    <div class="form-group col-md-8">
                        <label for="code" translate>search_by_bale.bale_code</label>
                        <input type="text" class="form-control" id="code" formControlName="code">
                    </div>
                    <div class="form-group col-md-4 d-flex align-items-end">
                        <button (click)="onSubmit()" class="btn btn-primary" translate>generic.buttons.search</button>
                    </div>
                </div>
                <div class="row label-valid-code">
                    <p class="form-group col-md-12">
                        <small translate>
                            search_by_bale.label_valid_code
                        </small>
                    </p>
                </div>
                <div *ngIf="devices.length>1" class="row">
                    <div class="form-group col-md-4">
                        <label for="status" translate>search_by_bale.labels.using_camera</label>
                        <select formControlName="status" id="status" class="form-control" (change)="setCamera($event)">
                            <option *ngFor="let device of devices" [selected]="desiredDevice?.groupId == device.groupId" [value]="device.groupId">{{ device.label }}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="alert alert-{{messageType}} col-md-5" *ngIf="message">{{message}}</div>
                    <div class="form-group col-md-5">
                        <button class="btn btn-outline-primary" (click)="toggleCamera()" translate>
                            <fa-icon [icon]="['fas', 'barcode']"></fa-icon>
                            search_by_bale.buttons.toggle_camera_bar_code
                        </button>
                    </div>
                    <div class="form-group col-md-2 d-flex align-items-end justify-content-center">
                        <label translate>search_by_bale.buttons.or</label>
                    </div>
                    <div class="alert alert-{{messageType}} col-md-5" *ngIf="message">{{message}}</div>
                    <div class="form-group col-md-5">
                        <button class="btn btn-outline-primary" (click)="toggleCamera()" translate>
                            <fa-icon [icon]="['fas', 'qrcode']"></fa-icon>
                            search_by_bale.buttons.toggle_camera_qr_code
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <zxing-scanner *ngIf="showCamera" [(device)]="desiredDevice" [formats]="allowedFormats"
                            (scanSuccess)="scanCompleteHandler($event)" (camerasFound)="camerasFoundHandler($event)"
                            (camerasNotFound)="camerasNotFoundHandler($event)">
                        </zxing-scanner>
                    </div>
                </div>
            </form>

            <div *ngIf="baleInformation == null" class="row d-flex justify-content-center">
                <img src="assets/images/barcode.jpeg" alt="barcode.jpeg">
            </div>

            <div *ngIf="baleInformation !== null; else elseBlock">
                <hr>
                <div class="row traceability-download">
                    <div class="col-md-6" translate>search_by_bale.traceability</div>

                    <div class="col-md-6" *ngIf="baleInformation?.hviInformationsDto !== null && baleInformation?.hviInformationsDto?.socialCertificationsDto != null">
                        <rastreabilidade-traceability-certificate [code]="codeSelected" [id]="baleInformation.id">
                        </rastreabilidade-traceability-certificate>
                    </div>
                </div>

                <div>
                    <rastreabilidade-bale-information [baleInformation]="baleInformation">
                    </rastreabilidade-bale-information>

                </div>

                <div>
                    <rastreabilidade-hvi-information [hviInformation]="baleInformation?.hviInformationsDto">
                    </rastreabilidade-hvi-information>
                </div>

                <ng-template #elseBlockHr>
                    <hr>
                </ng-template>
                <div *ngIf="baleInformation?.hviInformationsDto?.socialCertificationsDto != null">
                    <rastreabilidade-social-environmental-certification
                        [socialEnvironmentalCertification]="baleInformation?.hviInformationsDto?.socialCertificationsDto"
                        [cropYearDtoId]="baleInformation?.hviInformationsDto?.originOfProductionDto?.cropId"
                        [originOfProductionId]="baleInformation?.hviInformationsDto?.originOfProductionDto?.productiveUnitId"
                        [cottonOriginId]="baleInformation.cottonOriginDto.idUba"
                        [cropYearCottonDtoId]="baleInformation.cottonOriginDto.idSafraSai"
                        [mapaUrl]="baleInformation.mapaUrl"
                        [identificacaoMapa]="baleInformation.identificacaoMapa"
                        [canceledDate]="baleInformation.canceledDate"
                        [statusAnalysis]="baleInformation.statusAnalysis"
                        [isAnalyzed]="baleInformation?.isAllowCertificationMapa"
                        >
                    </rastreabilidade-social-environmental-certification>

                </div>

                <div *ngIf="baleInformation?.hviInformationsDto?.originOfProductionDto != null && baleInformation?.hviInformationsDto?.socialCertificationsDto != null">
                    <rastreabilidade-production-origin
                        [productionOrigin]="baleInformation?.hviInformationsDto?.originOfProductionDto">
                    </rastreabilidade-production-origin>

                </div>

                <div *ngIf="baleInformation?.cottonOriginDto != null">
                    <rastreabilidade-cotton-farm-origin [cottonFarmOrigin]="baleInformation?.cottonOriginDto">
                    </rastreabilidade-cotton-farm-origin>

                </div>

                <div *ngIf="baleInformation?.hviInformationsDto?.laboratoryDataDto != null">
                    <rastreabilidade-laboratory-data
                        [laboratoryData]="baleInformation?.hviInformationsDto?.laboratoryDataDto">
                    </rastreabilidade-laboratory-data>

                </div>

                <div *ngIf="baleInformation?.hviInformationsDto?.socialCertificationsDto == null && baleInformation?.hviInformationsDto?.originOfProductionDto.sbrhvi">
                    <div class="no-sbhrvi-information" translate>search_by_bale.hvi_not_available</div>
                </div>

                <div *ngIf="baleInformation?.hviInformationsDto == null || !baleInformation?.hviInformationsDto?.originOfProductionDto.sbrhvi">
                    <div class="no-sbhrvi-information" translate>search_by_bale.hvi_not_available</div>
                </div>

                <hr>
                <div>
                    <p class="p-black" translate>search_by_bale.are_there_still_any_doubts
                        <fa-icon [icon]="['fas', 'paper-plane']" style="color: #004f80; size: 16px;"></fa-icon>&nbsp;
                        <a [routerLink]="['contate-nos']" translate>search_by_bale.contact_us</a>
                    </p>
                </div>
            </div>
            <ng-template #elseBlock>

            </ng-template>
        </div>

    </card-body>
</card>
