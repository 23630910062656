import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/core/services/contact.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {


  formContact = this.fb.group({
    title: [null, [Validators.required]],
    message: [null, [Validators.required]]
  })

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private contactService: ContactService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  submit() {
    this.spinnerService.show();
    const title = this.formContact.value.title;
    const message = this.formContact.value.message;
    this.contactService.sendEmail(title, message).subscribe(res => {
      this.translateService.get('generic.toast.title.success').subscribe(msg => {
        this.toastService.showToast(ToastTypeEnum.SUCCESS, msg, res.data);
        this.formContact.reset();
        this.router.navigate(['/consulta-fardo-a-fardo'])
      });
    }, error => {
      this.translateService.get('generic.toast.title.error').subscribe(msg => {
        this.toastService.showToast(ToastTypeEnum.DANGER, msg, error);
      });
    }).add(() => {
      this.spinnerService.hide();
    });
  }

  return() {
    this.router.navigate(['/consulta-fardo-a-fardo'])
  }

}
