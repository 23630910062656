import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { EditAccessRequestComponent } from './edit-access-request/edit-access-request.component';
import { HomeManageAccessRequestComponent } from './home-manage-access-request/home-manage-access-request.component';
import { EditAccessRequesterComponent } from './edit-access-request/edit-access-requester/edit-access-requester.component';

const routes: Routes = [
    {
        path: '',
        component: HomeManageAccessRequestComponent
    },
    {
        path: 'editar/:id',
        component: EditAccessRequestComponent
    },
    {
        path: 'editar-solicitante/:id',
        component: EditAccessRequesterComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ManageRequestAccessRouting { }

