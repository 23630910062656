import { NgModule } from '@angular/core';
import { HomeConsultByBaleComponent } from './home-search-by-bale/home-search-by-bale.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ConsultByBaleRouting } from './search-by-bale-routing.module';
import { BaleInformationComponent } from './home-search-by-bale/bale-information/bale-information.component';
import { HviInformationComponent } from './home-search-by-bale/hvi-information/hvi-information.component';
import { SocialEnvironmentalCertificationComponent } from './home-search-by-bale/social-environmental-certification/social-environmental-certification.component';
import { ProductionOriginComponent } from './home-search-by-bale/production-origin/production-origin.component';
import { CottonFarmOriginComponent } from './home-search-by-bale/cotton-farm-origin/cotton-farm-origin.component';
import { LaboratoryDataComponent } from './home-search-by-bale/laboratory-data/laboratory-data.component';
import { TraceabilityCertificateComponent } from './home-search-by-bale/traceability-certificate/traceability-certificate.component';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './home-search-by-bale/contact-us/contact-us.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SharedModule } from 'src/app/shared/shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { SocialModalPdfComponent } from './home-search-by-bale/social-environmental-certification/social-modal-pdf/social-modal-pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    HomeConsultByBaleComponent,
    BaleInformationComponent,
    HviInformationComponent,
    SocialEnvironmentalCertificationComponent,
    ProductionOriginComponent,
    CottonFarmOriginComponent,
    LaboratoryDataComponent,
    TraceabilityCertificateComponent,
    ContactUsComponent,
    SocialModalPdfComponent
  ],
  imports: [
    CommonModule,
    ConsultByBaleRouting,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    FontAwesomeModule,
    ZXingScannerModule,
    SharedModule,    
    GoogleMapsModule,
    PdfViewerModule
  ]
})
export class ConsultByBaleModule { }
