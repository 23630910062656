import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'rastreabilidade-multi-steps-form',
    templateUrl: './multi-steps-form.component.html',
    styleUrls: ['./multi-steps-form.component.scss']
})
export class MultiStepsFormComponent implements OnInit {

    @Input() activeStepLabel: string;
    @Input() steps: Step[];
    @Input() esconderAndamentoCertificacao: boolean;

    @Output() clickStep = new EventEmitter<Step>();
    @Output() showAndamentoCertificacao = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void { }

    onShowAndamentoCertificacaoClick() {
        this.showAndamentoCertificacao.emit();
    }

    onClickStep(step: Step) {
        if (!step.active) {
            return;
        }
        this.clickStep.emit(step);
    }
}

export interface Step {
    label: string;
    active: boolean;
}
