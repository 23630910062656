import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProducerTradingModule } from './producer-trading/producer-trading.module';
import { AccreditationModule } from './accreditation/accreditation.module';
import { ReportsRoutingModule } from './reports-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RequesterReportComponent } from './requester/requester-report/requester-report.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RequesterReportComponent],
  imports: [
    NgbModule,
    CommonModule,
    ProducerTradingModule,
    AccreditationModule,
    ReportsRoutingModule,
    SharedModule,
    FontAwesomeModule,
    TranslateModule,
    NgbPaginationModule,
    BsDatepickerModule.forRoot(),
    RouterModule
  ]
})
export class ReportsModule { }
