import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AbrService } from 'src/app/core/services/abr.service';
import { SocialModalPdfComponent } from './social-modal-pdf/social-modal-pdf.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-social-environmental-certification',
  templateUrl: './social-environmental-certification.component.html',
  styleUrls: ['./social-environmental-certification.component.scss'],
})
export class SocialEnvironmentalCertificationComponent implements OnInit {
  @Input() public socialEnvironmentalCertification: any = null;
  @Input() public cropYearDtoId: any;
  @Input() public originOfProductionId: any;
  @Input() public cottonOriginId: any;
  @Input() public cropYearCottonDtoId: any;
  @Input() public mapaUrl: string;
  @Input() public identificacaoMapa: string;
  @Input() public statusAnalysis: string;
  @Input() public canceledDate: Date;
  @Input() public isAnalyzed: boolean;

  modalRef: BsModalRef<SocialModalPdfComponent> | null = null;

  constructor(
    private abrService: AbrService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {}

  downloadCertificadoBci() {
    this.abrService
      .downloadCertificadoBci(this.originOfProductionId, this.cropYearDtoId)
      .subscribe(
        (data) => {
          this.openModal(
            data.conteudo,
            this.socialEnvironmentalCertification?.bciLicensing
          );
        },
        (error) => {
          this.showToast();
        }
      );
  }
  downloadCertificadoMapa() {}

  gerarCertificado() {
    this.abrService
      .gerarCertificado(this.originOfProductionId, this.cropYearDtoId)
      .subscribe(
        (data) => {
          this.openModal(
            data.conteudo,
            this.socialEnvironmentalCertification?.abrCertification
          );
        },
        (error) => {
          this.showToast();
        }
      );
  }

  gerarCertificacaoAbrUba() {
    this.abrService
      .gerarCertificacaoAbrUba(this.cottonOriginId, this.cropYearCottonDtoId)
      .subscribe(
        (data) => {
          this.openModal(
            data.data.conteudo,
            this.socialEnvironmentalCertification?.abrUbaCertification
          );
        },
        (error) => {
          this.showToast();
        }
      );
  }

  openModal(conteudo: string, title: string) {
    this.modalRef = this.modalService.show(SocialModalPdfComponent, {
      backdrop: true,
      class: 'modal-dialog-centered modal-lg',
    });
    this.modalRef.content.pdfSrc = 'data:application/pdf;base64,' + conteudo;
    this.modalRef.content.title = title;
  }

  showToast() {
    setTimeout(() => 3000);
    this.translate.get('search_by_bale.code').subscribe((header) => {
      this.translate
        .get('search_by_bale.error_download_certificate')
        .subscribe((messageError) => {
          this.toast.showToast(ToastTypeEnum.DANGER, header, messageError);
        });
    });
  }
}
