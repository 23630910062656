import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeMonitoringComponent } from './home-monitoring/home-monitoring.component';
import { MonitoringConfigurationRoutingModule } from './monitoring-configuration-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NewMonitoringComponent } from './new-monitoring/new-monitoring.component';
import { TranslateModule } from '@ngx-translate/core';
import { EditMonitoringComponent } from './edit-monitoring/edit-monitoring.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    HomeMonitoringComponent,
    NewMonitoringComponent,
    EditMonitoringComponent
  ],
  imports: [
    CommonModule,
    MonitoringConfigurationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule,
    SharedModule
  ]
})
export class MonitoringConfigurationModule { }
