import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromPipes from './pipes/base-pipes';
import * as fromComponents from './components/index';
import * as fromDirectives from './directives/index';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalApproveComponent } from './components/modal-request-access/modal-approve/modal-approve.component';
import { ModalDisapproveReasonComponent } from './components/modal-request-access/modal-disapprove-reason/modal-disapprove-reason.component';
import { ModalRequestAccessResponseComponent } from './components/modal-request-access/modal-request-access-response/modal-request-access-response.component';
import { DynamicModalRequestAccessComponent } from './components/modal-request-access/dynamic-modal-request-access/dynamic-modal-request-access.component';
import { RouterModule } from '@angular/router';
import { TooltipTotalComponent } from './components/tooltip-total/tooltip-total.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [
    ...fromPipes.pipes,
    ...fromComponents.components,
    ...fromDirectives.directives,
    ModalApproveComponent,
    ModalDisapproveReasonComponent,
    ModalRequestAccessResponseComponent,
    DynamicModalRequestAccessComponent,
    TooltipTotalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
    GoogleMapsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...fromPipes.pipes,
    ...fromComponents.components,
    ...fromDirectives.directives
  ]
})
export class SharedModule { }
