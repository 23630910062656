import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() type:
    | 'primary'
    | 'success'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';

  @Input() hasIcon: boolean = true;

  ngOnInit(): void { }

  getIcon() {
    switch (this.type) {
      case 'primary': return 'exclamation-triangle';
      case 'secondary': return 'info-circle';
      case 'danger': return 'info-circle';
      case 'success': return 'info-circle';
      case 'warning': return 'info-circle';
      case 'info': return 'exclamation-triangle';
      case 'light': return 'info-circle';
      case 'dark': return 'info-circle';

      default:
        break;
    }
  }
}
