import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AccreditationService {

  private _route = {
    getAll: `Reports/reportFilteredRequests`,
    downloadCsvTempoMedio: `Reports/reportFilteredRequests/csvTempoMedio`,
    downloadCsvStatusCredenciamento: `Reports/reportFilteredRequests/csvStatusCredenciamento`
  }

  constructor(private services: HttpService) { }

  getAll(param: object = {}, skip: number, take: number): Observable<any> {
    return this.services.getWithParams(`${this._route.getAll}/${skip}/${take}`, param);
  }

  downloadArquivoCsvTempoMedio(param: string): Observable<any> {
    return this.services.getWithParams(`${this._route.downloadCsvTempoMedio}`, param);
  }

  downloadArquivoCsvStatusCredenciamento(param: string): Observable<any> {
    return this.services.getWithParams(`${this._route.downloadCsvStatusCredenciamento}`, param);
  }

  getStatus(): Observable<any> {
    return this.services.get(`${this._route.getAll}/status`);
  }
}
