import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeBatchSearchComponent } from './components/home-batch-search/home-batch-search.component';
import { RouterModule } from '@angular/router';
import { BatchSearchRoutingModule } from './batch-search-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NewRequestComponent } from './components/new-request/new-request.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ViewRequestComponent } from './components/view-request/view-request.component';
import { BurdenStatusPipe } from './pipes/burdenStatus.pipe';



@NgModule({
  declarations: [
    HomeBatchSearchComponent,
    NewRequestComponent,
    ViewRequestComponent,
    BurdenStatusPipe
  ],
  imports: [
    BatchSearchRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    FontAwesomeModule,
    SharedModule
  ]
})
export class BatchSearchModule { }
