<div class="modal-content">

  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    
  </div>
  <div class="modal-body">
    <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
      style="width: 776px; height: 500px"></pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="clickOk()" translate="ok"></button>
  </div>
</div>