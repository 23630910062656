import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cepformat'
})
export class CepFormatPipe implements PipeTransform {
  transform(cep: string): string {
    if (!!cep) {
      cep = cep.replace(/[^0-9]/g, '');
      return cep.slice(0, 2) + '.' + cep.slice(2, 5) + '-' + cep.slice(5, 8);
    }
    return cep;
  }
}
