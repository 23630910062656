import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { RespApi } from 'src/app/shared/models/response';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class AbrService {
    constructor(private services: HttpService) { }

    downloadCertificadoBci(unidadeProdutivaId, idSafra):  Observable<any> {
       return this.services.downloadCertificadoBci(unidadeProdutivaId, idSafra);
    }

    gerarCertificado(unidadeProdutivaId, idSafra):  Observable<any> {
        return this.services.gerarCertificado(unidadeProdutivaId, idSafra); 
     }
     gerarCertificacaoAbrUba(idUba, idSafra){
      return this.services.gerarCertificacaoAbrUba(idUba, idSafra);
     }
  }

  interface CertificadoAbr {
   conteudo: string,
   nomeArquivo: string
  }