import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  @Input() type: 'cloud-download-alt' | 'file-pdf' | 'file-csv';
  @Input() label: string = null;
  @Input() direction: 'row' | 'column' = 'column';
  @Input() size: string = '30px';
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  click($event) {
    this.onClick.emit($event);
  }

}
