import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RequestAccessComponent } from './request-access/request-access.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'pedir-acesso', component: RequestAccessComponent },
  { path: 'esqueci-senha', component: ForgotPasswordComponent },
  { path: 'accessRequestScreen', component: RegisterComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthRoutingModule { }

