<div class="container">
    <card class="card mb-5">
        <card-header>{{ 'certificate_configuration.form.title' | translate }}</card-header>
        <div class="card-body">
            <form class="form" [formGroup]="configForm" (ngSubmit)="onSubmit()">

                <div class="form-group">
                    <div class="card card__body d-flex col-md-12">
                        <h6 class="blue__subtitle" translate>certificate_configuration.form.labels.nomenclature</h6>
                    </div>
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [toolbar]="toolbar" [editor]="nomenclatureEditor"> </ngx-editor-menu>
                        <ngx-editor [editor]="nomenclatureEditor" rows="10" name="nomenclature"
                            formControlName="nomenclature"></ngx-editor>
                    </div>
                    <div class="text-danger col-12 mt-1" *ngIf="fSubmited && f.nomenclature.errors?.required">
                        {{ 'generic.validations.required' | translate }}
                    </div>
                </div>

                <div class="form-group">
                    <div class="card card__body d-flex col-md-12">
                        <h6 class="blue__subtitle" translate>certificate_configuration.form.labels.nomenclatureLeft</h6>
                    </div>
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [toolbar]="toolbar" [editor]="nomenclatureLeftEditor"> </ngx-editor-menu>
                        <ngx-editor [editor]="nomenclatureLeftEditor" rows="10" name="nomenclatureLeft"
                            formControlName="nomenclatureLeft"></ngx-editor>
                    </div>
                    <div class="text-danger col-12 mt-1" *ngIf="fSubmited && f.nomenclatureLeft.errors?.required">
                        {{ 'generic.validations.required' | translate }}
                    </div>
                </div>

                <div class="form-group">
                  <div class="card card__body d-flex col-md-12">
                      <h6 class="blue__subtitle" translate>certificate_configuration.form.labels.observetion</h6>
                  </div>
                  <div class="NgxEditor__Wrapper">
                      <ngx-editor-menu [toolbar]="toolbar" [editor]="observetionEditor"> </ngx-editor-menu>
                      <ngx-editor [editor]="observetionEditor" rows="10" name="observetion"
                          formControlName="observetion"></ngx-editor>
                  </div>
                  <div class="text-danger col-12 mt-1" *ngIf="fSubmited && f.observetion.errors?.required">
                      {{ 'generic.validations.required' | translate }}
                  </div>
              </div>

                <div class="form-group">
                    <div class="card card__body d-flex col-md-12">
                        <h6 class="blue__subtitle" translate>
                            certificate_configuration.form.labels.important_informations
                        </h6>
                    </div>
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [toolbar]="toolbar" [editor]="importantInformationEditor"> </ngx-editor-menu>
                        <ngx-editor [editor]="importantInformationEditor" name="importantInformation"
                            formControlName="importantInformation"></ngx-editor>
                    </div>
                    <div class="text-danger col-12 mt-1" *ngIf="fSubmited && f.importantInformation.errors?.required">
                        {{ 'generic.validations.required' | translate }}
                    </div>
                </div>

                <div class="form-group">
                    <div class="card card__body d-flex col-md-12">
                        <h6 class="blue__subtitle" translate>
                            certificate_configuration.form.labels.quality_of_program_sbrhvi
                        </h6>
                    </div>
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [toolbar]="toolbar" [editor]="qualityProgramSBRHVIEditor"> </ngx-editor-menu>
                        <ngx-editor [editor]="qualityProgramSBRHVIEditor" name="qualityProgramSBRHVI"
                            formControlName="qualityProgramSBRHVI"></ngx-editor>
                    </div>
                    <div class="text-danger col-12 mt-1" *ngIf="fSubmited && f.qualityProgramSBRHVI.errors?.required">
                        {{ 'generic.validations.required' | translate }}
                    </div>
                </div>

                <div class="d-flex justify-content-end col-12 form-group">
                    <button class="btn btn-primary" type="submit" translate>generic.buttons.save</button>
                </div>
            </form>
        </div>
    </card>
</div>
