import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class TranslateConfigService {
    private translate = new BehaviorSubject<string>('pt');


    constructor(private translateService: TranslateService) {
        let language = localStorage.getItem('locale')
        if (language === 'pt-BR')
            language = 'pt'

        if (language !== null) {
            this.translateService.use(language)
            this.translate.next(language)
        } else {
            this.translateService.use('pt')
            this.translate.next('pt')
        }
    }

    changeLanguage(type: string) {
        this.translateService.use(type);
        this.translate.next(type);
        localStorage.setItem('locale', type)
    }

    isTranslateVisibleAsObservable() {
        return this.translate.asObservable();
    }

    getLocale() {
        let locale = localStorage.getItem('locale');
        if(locale?.toLowerCase() === 'en')
            return 'en-US'
        else
            return 'pt-BR'
    }
}


