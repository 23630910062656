import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators'

import { ABR_API, ABR_UBA_API, APP_API } from "src/app/configurations/api";

@Injectable()
export class HttpService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credential': 'true',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };

  constructor(private http: HttpClient) { }

  getAll(controller): Observable<any[]> {
    return this.http
      .get<any[]>(`${APP_API}/${controller}`)
      .pipe(map((res) => res));
  }

  get<T = any>(controller): Observable<T> {
    return this.http.get<T>(`${APP_API}/${controller}`, this.httpOptions)
      .pipe(map(
        (res) => res)
      );
  }

  getBy<T = any>(controller: string, param: string | number): Observable<T> {
    return this.http.get<T>(
      `${APP_API}/${controller}/${param}`,
      this.httpOptions
    );
  }

  getWithParams(controller, params): Observable<any> {
    return this.http
      .get<any>(`${APP_API}/${controller}`, { params })
      .pipe(map((resp) => resp));
  }

  getByWithParams(controller, params): Observable<any> {
    return this.http
      .get<any>(`${APP_API}/${controller}`, { params: params })
      .pipe(map((resp) => resp));
  }

  getById(controller, id): Observable<any> {
    return this.http
      .get<any>(`${APP_API}/${controller}/${id}`)
      .pipe(map((resp) => resp));
  }

  post(controller, data: any): Observable<any> {
    return this.http
      .post<any>(`${APP_API}/${controller}`, data, this.httpOptions)
      .pipe(map((resp) => resp));
  }

  postWithParams(controller, params): Observable<any> {
    return this.http
      .post<any>(`${APP_API}/${controller}`, null, { params: params })
      .pipe(map((resp) => resp));
  }

  put(controller, data, id): Observable<any> {
    return this.http
      .put<any>(`${APP_API}/${controller}/${id}`, data, this.httpOptions)
      .pipe(map((resp) => resp));
  }

  putId(controller, id): Observable<any> {
    return this.http
      .put<any>(`${APP_API}/${controller}/${id}`, null, this.httpOptions)
      .pipe(map((resp) => resp));
  }

  putData(controller, data): Observable<any> {
    return this.http
      .put<any>(`${APP_API}/${controller}`, data, this.httpOptions)
      .pipe(map((resp) => resp));
  }


  putWithParams(controller, params, id): Observable<any> {
    return this.http
      .put<any>(`${APP_API}/${controller}/${id}`, null, { params: params })
      .pipe(map((resp) => resp));
  }

  delete(controller, id): Observable<any> {
    return this.http
      .delete<any>(`${APP_API}/${controller}/${id}`)
      .pipe(map((resp) => resp));
  }

  downloadCertificadoBci(unidadeProdutivaId, idSafra): Observable<any> {
    return this.http.get<any>(`${ABR_API}/DownloadCerticacao/${unidadeProdutivaId}/${idSafra}/downloadCertificadoBci`)
  }

  gerarCertificado(unidadeProdutivaId, idSafra): Observable<any> {
    return this.http.get<any>(`${ABR_API}/DownloadCerticacao/${unidadeProdutivaId}/${idSafra}/gerarCertificado`)
  }

  gerarCertificacaoAbrUba(idUba, idSafra): Observable<any>{    
    return this.http.get<any>(`${ABR_UBA_API}/api/Notificacao/${idUba}/${idSafra}/DownloadCertificado`)
  }
}
