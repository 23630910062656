import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RespApi } from 'src/app/shared/models/response';
import * as MonitoringModels from 'src/app/shared/models/monitoring';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  private routes = {
    all: `ConfiguringMonitoring`, // GET
    create: `ConfiguringMonitoring`, // POST
    get: `ConfiguringMonitoring`, // GET
    update: `ConfiguringMonitoring`, // PUT
    delete: `ConfiguringMonitoring`, // DELETE
    getUnitMeasures: `ConfiguringMonitoring/GetAllUnitMeasures`, // GET
    getActions: `ConfiguringMonitoring/GetAllActionsItems`, // GET
  };

  constructor(private services: HttpService) { }
  
  getActions(): Observable<RespApi<MonitoringModels.Action[]>> {
    return this.services.get(this.routes.getActions);
  }

  getUnitMeasures(): Observable<RespApi<MonitoringModels.UnitMeasure[]>> {
    return this.services.get(this.routes.getUnitMeasures);
  }

  all(params: object = {}): Observable<RespApi<MonitoringModels.Monitoring[]>> {
    return this.services.getWithParams(this.routes.all, params);
  }

  get(id: String): Observable<RespApi<MonitoringModels.DetailMonitoring>> {
    return this.services.getById(this.routes.get, id);
  }

  delete(id: String): Observable<RespApi<any>> {
    return this.services.delete(this.routes.delete, id);
  }

  create(data: MonitoringModels.NewMonitoring): Observable<RespApi<MonitoringModels.NewMonitoring>> {
    return this.services.post(this.routes.create, data);
  }

  update(data: MonitoringModels.UpdateMonitoring, id: String): Observable<RespApi<MonitoringModels.UpdateMonitoring>> {
    return this.services.put(this.routes.update, data, id);
  }
}
