import { Component, Input, OnInit } from '@angular/core';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent implements OnInit {

  @Input() text: string;

  icon = faQuestionCircle;

  constructor() { }

  ngOnInit(): void {
  }

}
