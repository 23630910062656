<ul class="nav mr-auto nav__menu">
    <li class="nav-item dropdown menu__itens">
        <a [routerLink]="['consulta-fardo-a-fardo']" class="nav-link main__item" translate>header.search_by_bale</a>
    </li>
    <li class="nav-item dropdown menu__itens">
        <a [routerLink]="['consulta-por-lote']" class="nav-link main__item" translate>header.batch_search</a>
    </li>
    <li class="nav-item dropdown menu__itens">
        <a [routerLink]="['gerenciar-pedidos-de-acesso']" class="nav-link main__item" translate>header.reports</a>
    </li>
</ul>