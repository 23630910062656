<span class="navbar-text">
    <ul class="navbar-nav">
        <li class="nav-item">
            <span class="nav-link text-dark" translate>
                    header.greetings <b>{{ service.loginUsuario }}</b>
            </span>
        </li>
        <li class="nav-item" (click)="service.logout()" >
            <a class="nav-link">
                <i class="fas fa-sign-out-alt"></i>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-box-arrow-right sair"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                    <path fill-rule="evenodd"
                        d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>&nbsp;<span class="text-dark" translate>header.logout</span>
            </a>
            <ng-template #sairTootip translate>header.tooltip_logout</ng-template>
        </li>
    </ul>
    <ul class="navbar-nav justify-content-end language__buttons">
        <button class="btn btn-light btn-sm" (click)="changeLang('pt')"><img src="../assets/icons/pt.png" alt="" translate>&nbsp;monitoring.form.labels.portuguese</button>&nbsp;&nbsp;
        <button class="btn btn-light btn-sm" (click)="changeLang('en')"><img src="../assets/icons/en.png" alt="" translate>&nbsp;monitoring.form.labels.english</button>
    </ul>
</span>