import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rastreabilidade-modal-sequence',
  templateUrl: './modal-sequence.component.html',
  styleUrls: ['./modal-sequence.component.scss']
})

export class ModalSequenceComponent implements OnInit {
  @Input() icon: string = 'check-circle';
  @Input() type: string = '';
  @Input() title: string = '';
  @Input() msg: string = '';
  @Input() cancelTxt: string = 'Voltar';
  @Input() requester: string = '';
  @Input() request: string = '';
  @Input() tableFrequency: any[] = [];
  @Input() total: number = 0;

  confirmResult: Subject<boolean>;

  constructor(private bsModalRef: BsModalRef, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.translateService.get(['GENERIC.BUTTONS.BACK']).subscribe(msg => {
      this.cancelTxt = msg['GENERIC.BUTTONS.BACK'];
    });
  }

  onClose() {
    this.close(false)
  }

  onDownloadCsv() {
    this.confirm(true)
  }

  private confirm(value: boolean) {
    this.confirmResult.next(value);
  }

  private close(value: boolean) {
    this.confirmResult.next(value);
    this.bsModalRef.hide();
  }
}
