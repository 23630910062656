import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rastreabilidade-abrapa-header',
  templateUrl: './abrapa-header.component.html',
  styleUrls: ['./abrapa-header.component.scss']
})
export class AbrapaHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
