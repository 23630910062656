import { Injectable, TemplateRef } from '@angular/core';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  // Push new Toasts to array with content and options
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  // Callback method to remove Toast DOM element from view
  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  /**
   * @param type type of toast if it´s success, alert or danger.
   * @param header message header of the toast.
   * @param message message body of the toast
   */
  showToast(type: ToastTypeEnum, header: string, message: string) {
    this.show(`${message}`, {
      classname: `bg-${type} text-light`,
      delay: 10000,
      autohide: true,
      headertext: `${header}`
    });
  }
}
