<div class="row">
    <div class="col-sm-12">
        <h6 class="subtitle__register mb-5" translate>
            manage_acccess_request.edit.history.component_name
        </h6>
        <div class="row">
            <ul class="timeline">
                <li *ngFor="let history of histories">
                    <div class="col-md-12 d-flex align-items-center justify-content-between">
                        <label>
                            <strong class="p-black" translate> 
                                manage_acccess_request.edit.history.updated_by
                            </strong> 
                            : {{ history.issuingUser }}
                        </label>
                        <label class="p-black float-right">
                            {{ history.sendDate | date: "dd/MM/yyyy HH:mm:ss" }}
                        </label>
                    </div>
                    <div class="col-md-12">

                        <label>{{ history.title }}</label>
                        <p>
                            {{ history.description }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <br />
    </div>
    <hr />
</div>
<br />