import { Component } from '@angular/core';
import { BaseComponent } from './core/base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  ngOnInit() {
    super.ngOnInit();
  }
}
