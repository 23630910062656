import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProducerRequesterComponent } from './producer-requester/producer-requester.component';
import { TradingRequesterComponent } from './trading-requester/trading-requester.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbPaginationModule, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalFrequencyComponent } from './modal-frequency/modal-frequency.component';
import { ModalSequenceComponent } from './modal-sequence/modal-sequence.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { RequestDetailComponent } from './request-detail/request-detail.component';

@NgModule({
  declarations: [
    ProducerRequesterComponent,
    TradingRequesterComponent,
    ModalFrequencyComponent,
    ModalSequenceComponent,
    RequestDetailComponent
  ],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    TranslateModule,
    NgbPaginationModule,
    BsDatepickerModule.forRoot(),
    RouterModule
  ],
  providers: [
    NgbTypeaheadConfig
  ]
})
export class ProducerTradingModule { }
