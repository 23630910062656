import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rastreabilidade-hvi-information',
  templateUrl: './hvi-information.component.html',
  styleUrls: ['./hvi-information.component.scss']
})
export class HviInformationComponent implements OnInit {

  @Input() public hviInformation: any = null

  constructor() { }

  ngOnInit(): void {
  }

}
