import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RespApi } from 'src/app/shared/models/response';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private route = 'contact';

  constructor(private services: HttpService) { }

  sendEmail(title: string, description: string): Observable<RespApi<string>> {
    return this.services.post(`${this.route}/sendEmail`, { title, description });
  }

  chatWithUs(email: string, title: string, description: string, locale: string): Observable<RespApi<string>> {
    return this.services.post(`${this.route}/chatWithUs?locale=${locale}`, { email, title, description });
  }

  validateToken(token: string, locale: string): Observable<RespApi<any>> {
    return this.services.get(`${this.route}/validateToken?token=${token}&locale=${locale}`);
  }
}
