import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestStatusComponent } from './request-status/request-status.component';
import { AverageTimeComponent } from './average-time/average-time.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule,NgbPaginationModule, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    RequestStatusComponent,
    AverageTimeComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    SharedModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    BsDatepickerModule.forRoot()
  ],
  providers:[
    NgbTypeaheadConfig
  ]
})
export class AccreditationModule { }
