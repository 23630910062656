import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';
import { HttpService } from '../services/http.service';
import { ModalService } from '../services/modal.service';
import { SpinnerService } from '../services/spinner.service';
import { ToastService } from '../services/toast.service';
import { TranslateConfigService } from '../services/translate-config.service';

@Component({
  selector: 'rastreabilidade-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnChanges {

  public spinnerVisibility: boolean = false;
  public showHeader: boolean = true;
  public showFooter: boolean = true;

  authorized = this.authService.isAuthorized();

  constructor(
    public httpService: HttpService,
    public authService: AuthService,
    public modalService: NgbModal,
    public appModalService: ModalService,
    public toast: ToastService,
    public fb: FormBuilder,
    private spinnerService: SpinnerService,
    public translateService: TranslateConfigService,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    var lang = localStorage.getItem('locale');
    lang = lang != null && lang != 'null' ? lang : 'pt';
    this.translateService.changeLanguage(lang);

    this.spinnerService
      .isVisibleAsObservable()
      .subscribe((isVisible) => (this.spinnerVisibility = isVisible));

    this.commonService
      .isHeaderVisibleAsObservable()
      .subscribe((isVisible) => (this.showHeader = isVisible));

    this.commonService
      .isFooterVisibleAsObservable()
      .subscribe((isVisible) => (this.showFooter = isVisible));
  }

  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  showSpinner() {
    this.spinnerService.show();
  }

  hideSpinner() {
    this.spinnerService.hide();
  }

  stringfyObject(value: any): string {
    return JSON.stringify(value);
  }

}
