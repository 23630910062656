import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'rastreabilidade-production-origin',
  templateUrl: './production-origin.component.html',
  styleUrls: ['./production-origin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductionOriginComponent implements OnInit {

  public googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query='
  @Input() public productionOrigin: any = null;

  constructor() { }

  ngOnInit(): void {
    if (this.productionOrigin !== null) {
      this.googleMapsUrl = `${this.googleMapsUrl}${this.productionOrigin?.addressHviDto.latitude}%2C${this.productionOrigin?.addressHviDto.longitude}`;
    }
  }

}
