<div class="container">

    <h6 class="subtitle__forgot__password">Recuperação de senha</h6>

    <form class="container container__forgot__password">
        <div class="form-row form__content">
            <div class="col-md-5 mb-3">
                <label for="email" class="lbl__forgot__password">E-mail Corporativo</label>
                <input type="email" class="form-control forgot__password__input" id="email" name="email"
                    placeholder="example@example.com">
            </div>
            <div class="col-md-3 mb-3">
                <button class="btn btn-primary btn__primary" type="submit">Recuperar Senha</button>
            </div>
        </div>
    </form>

</div>