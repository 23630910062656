import { KeycloakService } from "keycloak-angular";
import { KEYCLOAK_URL } from "./api";

export function initializeKeycloak(keycloak: KeycloakService) : () => Promise<boolean> {
    return () => 
        keycloak.init({
            config: {
                url: `${KEYCLOAK_URL}`,
                realm: 'ABRAPA',
                clientId: 'rastreabilidade_frontend'
            },
            initOptions: {
                checkLoginIframe: true,
                checkLoginIframeInterval: 5
            }
        })
}