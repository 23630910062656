import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { AuthService } from './auth.service';

interface User {
  name: string;
  preferredUsername: string;
  givenName: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  nomeUsuario: String;
  loginUsuario: String;
  user: User;
  inicializado = false;

  constructor(private authService: AuthService) {}

  async inicializar() {
    await this.authService.setToken();
    this.inicializado = false;
    this.carregarDadosUsuario();
  }

  carregarDadosUsuario() {
    let username = JSON.stringify(
      jwt_decode(window.localStorage.getItem('token'))
    );
    this.loginUsuario = JSON.parse(username).name;
    this.user = {
      name: JSON.parse(username).name,
      preferredUsername: JSON.parse(username).preferred_username,
      givenName: JSON.parse(username).given_name,
      email: JSON.parse(username).email,
    };
  }

  nomeSobrenome(str: string) {
    if (!str) {
      return '';
    }

    const arr = str.split(' ');
    if (arr.length === 1) {
      return arr[0];
    }

    if (arr[1][0].toUpperCase() != arr[1][0]) {
      arr.splice(1, 1);
    }
    const res = arr.slice(0, 2).join(' ');
    return res;
  }

  logout() {
    this.authService.logout();
  }
}
