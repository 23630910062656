import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServices } from 'src/app/core/services/common.services';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { TranslateService } from '@ngx-translate/core';
import { AccessService } from 'src/app/core/services/access.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
    selector: 'rastreabilidade-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements AfterViewInit {
    
    cpfInvalid: boolean = false;
    categories: any[] = [];
    selectedFile: File = null;
    base64File: string = '';
    fileName: string = '';
    fileExtension: string = '';
    fileSize: number = 0;
    identifiedSystems: any[] = null;
    isRequestesAdditionalData: boolean = false;
    toastMessage: string = '';
    toastTitleMessage: string = '';
    fileMessage: string = '';
    id: string = '';
    showBtn: boolean = true;
    isFileRequired: boolean = false;
    isDataRequired: boolean = false;

    registerForm = this.fb.group({
        name: ['', Validators.required],
        cpf_passport: ['', Validators.required],
        birth_date: ['', Validators.required],
        role: ['', Validators.required],
        corporative_telephone: ['', Validators.required],
        corporative_cellphone: ['', Validators.required],
        company_category: ['', Validators.required],
        company_name: ['', Validators.required],
        zip_code: ['', Validators.required],
        company_address: ['', Validators.required],
        city: ['', Validators.required],
        corporative_email: [{ value: null, disabled: true }, Validators.required],
        country: ['', Validators.required],
        company_website: ['', Validators.required],
        company_bond_file: [null],
        name_complement: [''],
        corporative_email_complement: [''],
        corporative_telephone_complement: [''],
        company_bond_file_name: [''],
        company_bond_file_extension: [''],
        company_bond_file_size: ['']
    })

    constructor(
        private route: ActivatedRoute,
        private services: CommonServices,
        private fb: FormBuilder,
        public toastService: ToastService,
        private router: Router,
        private translate: TranslateService,
        private accessService: AccessService,
        private spinnerService: SpinnerService
    ) { }

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe(data => {
            let receivedParam = data['cadastrar']
            if (receivedParam !== undefined) {
                let newParam = receivedParam.replace(/\s/g, '+')
                this.validatePreRequest(newParam);
            }
            else {
                let receivedParam = data['complementares']
                let newParam = receivedParam.replace(/\s/g, '+')
                this.validateRequestAdditionalData(newParam)
            }
        });
    }

    validatePreRequest(receivedParam: string) {
        this.accessService.getValidatePreRequest(receivedParam).subscribe(x => {
            this.registerForm.controls.corporative_email.setValue(x.data);
            this.loadCategories();
            this.loadSystems(x.data);
        }, (err) => {
            this.translate.get('generic.toast.title.warn').subscribe(msg => {
                this.toastService.showToast(ToastTypeEnum.ALERT, msg, err);
                this.router.navigate(['/']);
            });
        }
        ).add(() => {
            this.spinnerService.hide();
        });
    }

    validateRequestAdditionalData(receivedParam: string) {
        this.loadCategories();
        this.services.getBy('Access/additionalData', `${receivedParam}`).subscribe(x => {
            this.id = x.data.id;

            if (x.data.additionalInformation)
                this.isDataRequired = true;

            if (x.data.bondLetter)
                this.isFileRequired = true;

            this.addValidationAdditionalData()
            this.fileName = x.data.company_bond_file_name
            this.isRequestesAdditionalData = true;
            const [birth_date] = x.data.birth_date.split('T');
            this.registerForm.patchValue({
                ...x.data,
                birth_date,
                options: this.addValidationAdditionalData
            });

        }, (err) => {
            this.translate.get('generic.toast.title.warn').subscribe(msg => {
                this.toastService.showToast(ToastTypeEnum.ALERT, msg, err);
                this.router.navigate(['/']);
            });
        }
        ).add(() => {
            this.spinnerService.hide();
        });
    }

    confirm() {
        this.registerForm.controls.company_bond_file.setValue(this.base64File.split(',')[1]);
        this.registerForm.controls.company_bond_file_name.setValue(this.fileName);
        this.registerForm.controls.company_bond_file_extension.setValue(('.' + this.fileExtension));
        this.registerForm.controls.company_bond_file_size.setValue(this.fileSize);

        let data = this.registerForm.getRawValue();
        if (!this.isRequestesAdditionalData) {
            this.services.post(`Access/request`, data).subscribe(x => {
                this.translateToast();
                this.toastService.showToast(ToastTypeEnum.INFO, this.toastTitleMessage, this.toastMessage);
                this.router.navigate(['/']);
            }, (err) => {
                this.translate.get('generic.toast.title.error').subscribe(msg => {
                    this.toastService.showToast(ToastTypeEnum.DANGER, msg, err)
                });
            });
        } else {
            data.id = this.id;
            this.services.putData(`Access/additionalData`, data).subscribe(x => {
                this.translateToast();
                this.toastService.showToast(ToastTypeEnum.INFO, this.toastTitleMessage, this.toastMessage);
                this.router.navigate(['/']);
            }, err => {
                this.translate.get('generic.toast.title.error').subscribe(msg => {
                    this.toastService.showToast(ToastTypeEnum.DANGER, msg, err)
                });
            });
        }
    }

    loadCategories() {
        this.services.get(`Company/categoryCompany?locale=${localStorage.getItem('locale')}`).subscribe(x => {
            this.categories = x.data
        })
    }

    loadSystems(email: string) {
        this.services.get(`UserSinda/userSinda/${email}`).subscribe(x => {
            this.identifiedSystems = x?.data?.applicationsAccess;
        },
            error => { })
    }

    async onFileSelected(event) {
        this.selectedFile = <File>event.target.files[0]
        this.fileName = this.selectedFile.name
        this.fileSize = this.selectedFile.size
        this.fileExtension = this.selectedFile.name.substr(this.selectedFile.name.lastIndexOf('.') + 1);
        await this.fileToBase64();
        if (this.showBtn)
            this.showBtn = false;

        this.registerForm.controls.company_bond_file_name.setValue(this.fileName);

        if (!this.validateExtension(this.fileExtension)) {
            this.fileName = null;
            this.translate.get('generic.invalid_file_type').subscribe(msg => this.fileMessage = msg);
            this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', this.fileMessage)
        }
    }


    removeFile() {
        this.selectedFile = null;
        this.fileName = null;
        this.fileSize = null;
        this.fileExtension = null;
        this.base64File = null;
        this.showBtn = true;
    }

    validateExtension(extension: string): boolean {
        if (extension === 'pdf' || extension === 'png' || extension === 'jpeg' || extension === 'jpg')
            return true;

        return false;
    }

    stringfyObject(value: any): string {
        return JSON.stringify(value);
    }

    async fileToBase64() {
        let reader = new FileReader();
        reader.readAsDataURL(this.selectedFile as Blob);
        reader.onloadend = async () => {
            this.base64File = await reader.result as string
        }
    }

    translateToast() {
        this.translate.get('generic.toast.default_message.success').subscribe(msg => this.toastMessage = msg);
        this.translate.get('generic.toast.title.success').subscribe(msg => this.toastTitleMessage = msg);
    }

    addValidationAdditionalData() {
        if (this.isDataRequired) {
            this.registerForm.controls.name_complement.setValidators([Validators.required]);
            this.registerForm.controls.corporative_email_complement.setValidators([Validators.required, Validators.email]);
            this.registerForm.controls.corporative_telephone_complement.setValidators([Validators.required]);
            this.registerForm.updateValueAndValidity();
        }

        if (this.isFileRequired) {
            this.registerForm.controls.company_bond_file_name.setValidators([Validators.required])
            this.registerForm.updateValueAndValidity();
        }
    }

    validarCPF(cpf) {
        cpf = cpf.replace(/[^\d]/g, '');
        if (cpf.length !== 11) {
          return false;
        }
        if (/^(\d)\1+$/.test(cpf)) {
          return false;
        }
        let soma = 0;
        for (let i = 0; i < 9; i++) {
          soma += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let resto = 11 - (soma % 11);
        let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;
        if (digitoVerificador1 !== parseInt(cpf.charAt(9))) {
          return false;
        }
        soma = 0;
        for (let i = 0; i < 10; i++) {
          soma += parseInt(cpf.charAt(i)) * (11 - i);
        }
        resto = 11 - (soma % 11);
        let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;
        if (digitoVerificador2 !== parseInt(cpf.charAt(10))) {
          return false;
        }
        return true;
      
    }

    onExitCpfPassaport(target) {
        if (target.target.value.length == 14) {
          this.cpfInvalid = !this.validarCPF(target.target.value);
          console.log(this.cpfInvalid);
        }
      }
}
