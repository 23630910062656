<div class="modal-content modal__{{ type }}">
  <div class="modal-header">
    <h5 class="modal-title modal-title-{{ type }} title__message">{{ title }}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="body__message">
      {{ msg }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="onClose()">{{ cancelTxt }}</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">{{ okTxt }}</button>
  </div>
</div>
