import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AccreditationService } from 'src/app/core/services/accreditation.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { RequestStatusReport } from 'src/app/shared/models/reports/request-status-report';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ReportsService } from 'src/app/core/services/reports.service';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'rastreabilidade-request-status',
    templateUrl: './request-status.component.html',
    styleUrls: ['./request-status.component.scss']
})
export class RequestStatusComponent implements OnInit {

    page = 1;
    pageSize = 10;
    collectionSize = 0;
    currentPageSize = 10;
    isLoading: boolean = false;
    requestStatusReports: RequestStatusReport[] = [];
    searchedBurden: number = 0;
    totalForeign: number = 0;
    totalNational: number = 0;
    status: any[] = [];
    requestersList: any;
    bsConfig?: Partial<BsDatepickerConfig>

    formFilter = this.fb.group({
        requesterId: [null],
        requestStatusId: [null],
        brazil: [null],
        startPeriod: [null],
        endPeriod: [null],
        initDateInput: [''],
        finishDateInput: ['']
    });

    constructor(
        private producerservice: ReportsService,
        private service: AccreditationService,
        private fb: FormBuilder,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private config: NgbTypeaheadConfig,
        private dateLocale: BsLocaleService) {
        this.dateLocale.use(localStorage.getItem('locale'))
    }

    ngOnInit(): void {
        this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue'});

        this.formFilter.get('initDateInput').valueChanges.subscribe(ret => {
            let date = ret != '' ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(-2)}-${('0' + ret?.getDate()).slice(-2)}` : '';
            this.formFilter.controls.startPeriod.setValue(date);
        });

        this.formFilter.get('finishDateInput').valueChanges.subscribe(ret => {
            let date = ret != '' ? `${ret?.getFullYear()}-${('0' + (ret?.getMonth() + 1)).slice(-2)}-${('0' + ret?.getDate()).slice(-2)}` : '';
            this.formFilter.controls.endPeriod.setValue(date);
        });
        
        this.refreshPage();
    }

    refreshPage(): void {
        this.showSpinner();
        if (!this.validateDate())
            return;
        this.loadData();
        this.LoadStatus();
    }

    LoadStatus() {
        this.service.getStatus().subscribe(res => {
            this.status = res.data;
        });
    }

    showSpinner() {
        this.isLoading = true;
        this.spinnerService.show();
    }

    hideSpinner() {
        this.isLoading = false;
        this.spinnerService.hide();
    }

    clearFilter(event, inputRequester) {

        this.setValueForm(event, '', inputRequester)

        this.formFilter?.controls?.queryType?.setValue(1);
        this.formFilter?.controls?.statusRequest?.setValue('');
        this.formFilter?.controls?.requesterId.setValue(null);
        this.formFilter?.controls?.initDateInput?.setValue('');
        this.formFilter?.controls?.finishDateInput?.setValue('');
        this.formFilter?.controls?.startPeriod?.setValue(null);
        this.formFilter?.controls?.endPeriod?.setValue(null);
        this.formFilter?.controls?.periodQueryDate?.setValue(null);
        this.formFilter?.controls?.validateDate?.setValue(null);

        this.refreshPage();
    }

    validateDate(): boolean {
        if (this.formFilter.controls.startPeriod.value > this.formFilter.controls.endPeriod.value) {
            this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', `Data de início do período maior que a data fim.`);
            this.spinnerService.hide();
            return false;
        }

        return true;
    }

    loadData() {
        this.service.getAll(this.formFilter.getRawValue(), this.page, this.pageSize).subscribe(res => {
            this.requestStatusReports = res.data.reports;
            this.currentPageSize = res.data.reports.length;
            this.collectionSize = res.data.total;
            this.totalForeign = res.data.foreignTotal;
            this.totalNational = res.data.nacionalTotal;
            this.spinnerService.hide();
        });
    }

    downloadArquivoCsv() {
        this.service.downloadArquivoCsvStatusCredenciamento({ ...this.formFilter.getRawValue(), page: this.page, pageSize: this.pageSize }).subscribe(res => {
            if (res.success && res.data) {
                const blob = new Base64ToBlob().convertToBlob(
                    res.data,
                    'text/csv'
                );
                FileSaver.saveAs(blob, `relatorio`);
            } else {
                this.toastService.showToast(
                    ToastTypeEnum.DANGER,
                    'Erro',
                    'Erro ao baixar o certificado'
                );
            }
            this.spinnerService.hide();
        });
    }

    setValueForm(event: any, selectedControl: string, input: any = null) {
        switch (selectedControl) {
            case 'requesterId':
                this.formFilter.controls.requesterId.setValue(event.item.key);
                break;
            default:
                event.preventDefault();
                input.value = '';

        }
    }
    
    formatterRequester(x: { value: string }) {
        return x.value
    }
    searchRequester = (text$: Observable<string>) =>
        text$.pipe(
            distinctUntilChanged(),
            map(term => term.length < 2 ? []
                : this.getRequester(term)
            )
        )

    getRequester(param: string) {
        this.producerservice.getRequester(param).subscribe(res => {
            this.requestersList = res.data
        });
        return this.requestersList;
    }
}
