import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { RequestAccessComponent } from './request-access/request-access.component';
import { AdditionalDataComponent } from './additional-data/additional-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ManageAccessRequestModule } from '../manage-access-request/manage-access-request.module';
import { HistoryComponent } from '../manage-access-request/edit-access-request/history/history.component';
import { UserHistoryComponent } from './register/user-history/user-history.component';



@NgModule({
  declarations: [
    ForgotPasswordComponent,
    RegisterComponent,
    LoginComponent,
    RequestAccessComponent,
    AdditionalDataComponent,
    UserHistoryComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    NgbModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule { }
