import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/auth.guard';
import { HomeComponent } from './core/home/home.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module')
      .then(l => l.LoginModule)
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gerenciar-pedidos-de-acesso',
    loadChildren: () => import('./modules/manage-access-request/manage-access-request.module')
      .then(l => l.ManageAccessRequestModule),
    canActivate: [AuthGuard],
    data: {
      role: ['abrapa']
    }
  },
  {
    path: 'consulta-fardo-a-fardo',
    loadChildren: () => import('./modules/search-by-bale/search-by-bale.module')
      .then(l => l.ConsultByBaleModule),
    canActivate: [AuthGuard],
    data: {
      role: ['solicitante']
    }
  },
  {
    path: 'consulta-por-lote',
    loadChildren: () => import('./modules/batch-search/batch-search.module')
      .then(l => l.BatchSearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuracao-certificado',
    loadChildren: () => import('./modules/certificate-configuration/certificate-configuration.module')
      .then(l => l.CertificateConfigurationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuracao-monitoramento',
    loadChildren: () => import('./modules/monitoring-configuration/monitoring-configuration.module')
      .then(l => l.MonitoringConfigurationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./modules/reports/reports.module')
      .then(l => l.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fale-conosco',
    loadChildren: () => import('./modules/chat-with-us/chat-with-us.module')
      .then(l => l.ChatWithUsModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
