import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'burdenStatus'
})
export class BurdenStatusPipe implements PipeTransform {
  transform(code: number): string {

    switch (code) {
      case 1:
        return 'Pendente';
      case 2:
        return 'Aprovado';
      case 3:
        return 'Reprovado';
      case 4:
        return 'Não Participante';
      case 5:
          return 'Concluído';
      case 99:
        return 'Códigos Inválidos';

      default:
        return null
    }
  }
}

