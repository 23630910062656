import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rastreabilidade-requester-header',
  templateUrl: './requester-header.component.html',
  styleUrls: ['./requester-header.component.scss']
})
export class RequesterHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
