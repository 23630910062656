import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rastreabilidade-bale-information',
  templateUrl: './bale-information.component.html',
  styleUrls: ['./bale-information.component.scss']
})
export class BaleInformationComponent {

  @Input() public baleInformation: any = null;

}
