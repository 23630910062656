import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HeaderService } from '../services/header.service';
import { HttpService } from '../services/http.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { EventsService } from '../services/events.service';

@Component({
  selector: 'rastreabilidade-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public isAbrapa: boolean = false;
  public isSolicitante: boolean = false;
  authorized = false;
  showHeader: boolean = false;
  languageSelected: string = 'pt';
  accessRequestId: string;

  constructor(
    public service: HeaderService,
    public authService: AuthService,
    public translateService: TranslateConfigService,
    public httpService: HttpService,
    public router: Router,
    private eventService: EventsService
  ) {}

  async ngOnInit() {
    this.showHeader = window.location.pathname != '/';
    await this.service.inicializar();
    this.authorized = await this.authService.isAuthorized();
    this.translateService.changeLanguage(localStorage.getItem('locale'));

    this.userRoles();

    this.translateService
      .isTranslateVisibleAsObservable()
      .subscribe((language) => (this.languageSelected = language));
  }

  ngAfterViewInit(): void {
    this.eventService._accessRequests.subscribe((accessRequests) => {
      if (accessRequests) {
        let requests = accessRequests.filter((request) => request.name == this.service.loginUsuario)
        if(requests?.length != 0){
          this.accessRequestId = requests[0].id;
        }
      }
    });
  }

  userRoles() {
    let roles = this.authService.getRoles();
    this.isAbrapa = roles?.filter((x) => x === 'abrapa')?.length > 0;
    this.isSolicitante = roles?.filter((x) => x === 'solicitante')?.length > 0;
  }

  changeLang(type: string) {
    this.translateService.changeLanguage(type);
    this.httpService
      .putData(`User`, { locale: type === 'pt' ? 1 : 2 })
      .subscribe((res) => {
        document.location.reload();
      });
  }

  getLanguage() {
    switch (this.languageSelected) {
      case 'pt':
        return 'Português';
      case 'en':
        return 'English';

      default:
        break;
    }
  }
}
