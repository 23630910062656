import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.scss']
})
export class CardBodyComponent implements OnInit {
  @Input() class?: string = '';

  ngOnInit(): void { }
}
