import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { APP_API, APP_URL } from '../../configurations/api';
import { User } from 'src/app/shared/models/auth/user';
import { KeycloakService } from 'keycloak-angular';
import jwt_decode from "jwt-decode";
import { TranslateConfigService } from './translate-config.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private authorized = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      'Access-Control-Allow-Headers': 'Content-Type'
    })
  };

  constructor(private http: HttpClient, private keyCloakService: KeycloakService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  addAuthenticationToken(request: HttpRequest<any>) {

    this.keyCloakService.updateToken(-1).then(x => x).catch(y => {
      if (y !== undefined && y)
        this.logout();
    });

    const token = this.getTokenLocal();

    if (request.url.includes(APP_API))
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

    return request;
  }

  getTokenLocal() {
    let tokenAuth = window.localStorage.getItem('token')
    return tokenAuth;
  }

  logout() {
    this.setAuthorized(false);
    localStorage.removeItem('token');
    localStorage.removeItem('locale');
    this.keyCloakService.logout(APP_URL);
  }

  getRoles(): string[] {
    let tokenParsed = JSON.stringify(jwt_decode(this.getTokenLocal()));
    let roles;
    if (JSON.parse(tokenParsed).realm_access)
      roles = JSON.parse(tokenParsed).realm_access.roles;
    else
      roles = JSON.parse(tokenParsed).roles
    return roles;
  }

  async isAuthorized() {
    await this.setToken();

    if (this.getTokenLocal() != '')
      return true;
    else
      false;
  }

  async setToken() {
    localStorage.setItem('token', await this.keyCloakService.getToken());
    this.setLanguageToken();
  }

  setAuthorized(isAuthorized) {
    this.authorized = isAuthorized;
  }

  setLanguageToken() {
    let tokenParsed = JSON.stringify(jwt_decode(this.getTokenLocal()))
    let language = JSON.parse(tokenParsed).locale
    if (language === 'pt-BR')
      language = 'pt'
    localStorage.setItem('locale', language)
  }
}

