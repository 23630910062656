import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonServices } from 'src/app/core/services/common.services';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { BurdenService } from 'src/app/core/services/burden.service';
import { AlertModalService } from 'src/app/core/services/alert-modal.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { Router } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { EventsService } from '../../../core/services/events.service';
import { HeaderService } from '../../../core/services/header.service';

@Component({
  selector: 'rastreabilidade-home-search-by-bale',
  templateUrl: './home-search-by-bale.component.html',
  styleUrls: ['./home-search-by-bale.component.scss'],
})
export class HomeConsultByBaleComponent implements OnInit {
  isLoading: boolean = false;
  page = 1;
  pageSize = 10;

  filterAccessRequest = this.fb.group({
    requester_id: [null],
    cpf_passport: [''],
    status: [null],
    country: [''],
    corporative_email: [''],
    company_name: [''],
    start_period: [null],
    end_period: [null],
    initDateInput: [''],
    finishDateInput: [''],
  });

  public allowedFormats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.EAN_13,
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
  ];
  public showCamerasNotFoundMsg: boolean = false;
  public devices = [];
  public desiredDevice = null;
  public barcodeValue = null;
  public baleInformation: any = null;
  public showCamera: boolean = false;
  public message: string;
  public messageType: string = 'warning';
  public codeSelected: string = null;

  searchBaleForm = this.fb.group({
    code: [
      null,
      [Validators.required, Validators.maxLength(20), Validators.minLength(20)],
    ],
  });

  constructor(
    private burdenService: BurdenService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private toast: ToastService,
    private router: Router,
    public services: HttpService,
    private eventService: EventsService,
    private toastService: ToastService,
    private headerService: HeaderService
  ) {}
  ngOnInit(): void {
    this.filterAccessRequest.patchValue({
      corporative_email: this.headerService.user.email,
    });
    this.getRequests();
  }

  onSubmit() {
    if (this.searchBaleForm.invalid) {
      this.translate.get('search_by_bale.invalid_code').subscribe((msg) => {
        this.translate.get('search_by_bale.code').subscribe((header) => {
          this.toast.showToast(ToastTypeEnum.DANGER, header, msg);
        });
      });
      return;
    }
    this.spinnerService.show();
    this.baleInformation = null;

    this.codeSelected = this.searchBaleForm.controls.code.value;
    this.burdenService.getBurden(this.codeSelected).subscribe(
      (res) => {
        this.baleInformation = res.data;
        if (this.baleInformation == null)
          this.translate.get('search_by_bale.invalid_code').subscribe((tr) => {
            this.translate.get('search_by_bale.code').subscribe((header) => {
              this.toast.showToast(ToastTypeEnum.DANGER, header, tr);
            });
          });

        this.searchBaleForm.reset();
        this.spinnerService.hide();
      },
      (error) => {
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 3000);
        this.translate.get('search_by_bale.code').subscribe((header) => {
          this.toast.showToast(ToastTypeEnum.DANGER, header, error);
        });
      }
    );
  }

  toggleCamera() {
    this.message = null;
    let scanner = new ZXingScannerComponent();

    scanner.askForPermission().then((hasPermission) => {
      if (hasPermission === false) {
        this.translate
          .get(
            'search_by_bale.messages.user_has_denied_permission_to_acces_the_camera'
          )
          .subscribe((res) => {
            this.message = res;
          });
      }

      if (hasPermission === undefined) {
        this.translate
          .get('search_by_bale.messages.require_permission_to_acces_the_camera')
          .subscribe((res) => {
            this.message = res;
          });
      }

      if (hasPermission === null) {
        this.translate
          .get(
            'search_by_bale.messages.cant_check_permission_to_acces_the_camera'
          )
          .subscribe((res) => {
            this.message = res;
          });
      }

      if (hasPermission) {
        this.searchBaleForm.controls.code.setValue(null);
        this.showCamera = !this.showCamera;
      } else {
        this.translate
          .get('search_by_bale.messages.require_permission_to_acces_the_camera')
          .subscribe((res) => {
            this.message = res;
          });
      }
    });
  }

  setCamera(event) {
    const device = this.devices.find((d) => d.groupId == event.target.value);
    this.desiredDevice = device;
  }

  scanCompleteHandler(result: string) {
    if (result !== undefined) {
      this.toggleCamera();
      if (result.length > 20) result = result.split('cb=')[1];

      this.searchBaleForm.controls.code.setValue(result);
      this.onSubmit();
    }
  }

  camerasNotFoundHandler(event) {
    this.translate
      .get('search_by_bale.messages.camera_not_found')
      .subscribe((res) => {
        this.message = res;
      });
  }

  camerasFoundHandler(devices) {
    this.devices = devices;
  }

  teste = {
    id: '',
    nome: '',
  };

  private _filterRequest() {
    if (this.filterAccessRequest.value.status === null) {
      return {
        ...this.filterAccessRequest.value,
        status: '',
      };
    }
    return this.filterAccessRequest.value;
  }

  getRequests() {
    this.services
      .getWithParams(`Access/requests`, {
        ...this._filterRequest(),
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      .subscribe(
        (res) => {
          this.eventService._accessRequests.emit(res.data.data);
        },
        (err) => {
          this.toastService.showToast(ToastTypeEnum.DANGER, 'Error', err);
        }
      )
      .add(() => {
        this.hideSpinner();
      });
  }

  hideSpinner() {
    this.isLoading = false;
    this.spinnerService.hide();
  }
}
