<card>
    <card-header>
        <div class="container" translate>register.sai_traceability</div>
    </card-header>
    <card-body>

        <div class="container">
            <div class="container container__form__register">
                <p translate>contact.request_for_more_information</p>
                <br>
                <form [formGroup]="formContact">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="title">{{ 'contact.subject_title' | translate }} <required>
                                </required>
                            </label>
                            <input type="text" formControlName="title"
                                [ngClass]="{ 'is-invalid': formContact.controls.title.errors && formContact.controls.title.touched }"
                                class="form-control" id="title">
                            <div id="title" class="invalid-feedback" translate>
                                register.required_field
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="message">{{ 'contact.description' | translate }} <required>
                                </required>
                            </label>
                            <textarea class="form-control" formControlName="message"
                                [ngClass]="{ 'is-invalid': formContact.controls.message.errors && formContact.controls.message.touched }"
                                id="message" rows="5"></textarea>
                            <div id="message" class="invalid-feedback" translate>
                                register.required_field
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <hr>
                    <div class="form-row d-flex justify-content-end">
                        <div class="form-group col-md-3">
                            <button class="col-md-12 btn btn-outline-primary" (click)="return()"
                                translate>generic.buttons.cancel</button>
                        </div>
                        <div class="form-group col-md-3">
                            <button class="col-md-12 btn btn-primary" (click)="submit()" [disabled]="!formContact.valid"
                                translate>generic.buttons.send</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </card-body>
</card>