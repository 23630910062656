<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <textarea class="col-md-12" [(ngModel)]="reasonFailure" name="disapprove_reason" id="disapprove_reason"
            rows="5"></textarea>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="onClose()">{{ cancelTxt }}</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">{{ okTxt }}</button>
    </div>
</div>