import { EventEmitter, Injectable, Output } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export class EventsService {

    @Output() 
    _accessRequests = new EventEmitter<any>();


}