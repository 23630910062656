import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular";
import { BurdenService } from 'src/app/core/services/burden.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
    selector: 'rastreabilidade-new-request',
    templateUrl: './new-request.component.html',
    styleUrls: ['./new-request.component.scss']
})
export class NewRequestComponent implements OnInit {
    success: boolean = false;
    error: boolean = false;
    internalError: boolean = false;

    multiples: boolean = false;
    files: File[] = [];
    changeText: boolean = false;

    constructor(
        private burdenService: BurdenService,
        private spinnerService: SpinnerService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    onFileDropped($event) {
        this.spinnerService.show();
        this.prepareFilesList($event);
    }

    prepareFilesList(files: Array<File>) {
        if (this.multiples) {
            for (const item of files) {
                this.files.push(item);
            }

        } else {
            for (let i = 0; i < files.length; i++) {
                this.deleteFile(0);
            }

            this.validateFile(files[(files.length - 1)]);
        }
    }

    validateFile(file: File) {
        var reader = new FileReader();
        reader.onload = () => {
            var listaFardoTxt = reader.result.toString().replace("\r", "");
            var listaFardo = listaFardoTxt.split("\n").filter(fardo => fardo.length == 20);
            if (listaFardo.length <= 1) {
                this.translateService.get(['generic.invalid_file_content', 'generic.messages.list_burden']).subscribe(msg => {
                    this.toastService.showToast(
                        ToastTypeEnum.ALERT,
                        msg['generic.invalid_file_content'],
                        msg['generic.messages.list_burden']
                    );

                    this.spinnerService.hide();
                });

                return
            }

            var fileContent = reader.result.toString().replace(/[0-9]{20}/g, '').trim();
            if (fileContent.length === 0) {
                this.files.push(file);
                this.spinnerService.hide();
            } else {
                this.translateService.get(['generic.invalid_file', 'generic.invalid_file_content']).subscribe(msg => {
                    this.toastService.showToast(
                        ToastTypeEnum.DANGER,
                        msg['generic.invalid_file'],
                        msg['generic.invalid_file_content']
                    );

                    this.spinnerService.hide();
                });
            }
        };
        reader.readAsText(file);
    }

    fileBrowseHandler($event) {
        this.prepareFilesList($event.target.files);
    }

    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    sendBurdenRequest() {
        this.spinnerService.show();
        try {
            const file = this.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.burdenService.sendBurdenRequest(reader.result.toString()).subscribe(res => {
                    if (res.success) {
                        this.translateService.get(['burden_requests.messages.request_created', 'generic.toast.title.success']).subscribe(msg => {
                            this.toastService.showToast(
                                ToastTypeEnum.SUCCESS,
                                msg['generic.toast.title.success'],
                                msg['burden_requests.messages.request_created']
                            );

                            this.router.navigate(['/consulta-por-lote']);
                            this.spinnerService.hide();
                        });

                    } else {
                        this.success = false;
                        this.error = true;
                        this.internalError = false;
                        this.spinnerService.hide();
                    }
                },
                    (err) => {
                        this.toastService.showToast(
                            ToastTypeEnum.DANGER,
                            "",
                            err);
                    }
                );
            };
        } catch (err) {
            Sentry.captureException(err);
            this.success = false;
            this.error = false;
            this.internalError = true;
            this.spinnerService.hide();
        }
    }

    downloadTemplate() {
        let link = document.createElement("a");
        link.download = "template";
        link.href = "assets/templates/template.txt";
        link.click();
    }
}
