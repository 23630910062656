<h5 class="title__register" translate>register.sai_traceability</h5>
<br>
<div class="container">
  <h6 class="subtitle__register" translate>register.access_request_registration_form</h6>
  <br>
  <form [formGroup]="registerForm">
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="name" class="lbl__thinner">{{ 'register.full_name' | translate }} <required></required></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.name.errors && registerForm.controls.name.touched }"
          formControlName="name" id="name">
        <div id="name" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="cpf_passport" class="lbl__thinner">{{'register.passport' | translate}} <required></required></label>
        <input type="text" class="form-control form__input" (blur)="onExitCpfPassaport($event)"
          [ngClass]="{ 'is-invalid': registerForm.controls.cpf_passport.errors && registerForm.controls.cpf_passport.touched }"
          formControlName="cpf_passport" id="cpf_passport">
          <div *ngIf="cpfInvalid; else msgRequiredCpf">
            register.cpf_invalid_field
          </div>   
          <ng-template #msgRequiredCpf>
            register.required_field
          </ng-template>
      </div>
      <div class="form-group col-md-4">
        <label for="birth_date" class="lbl__thinner">{{ 'register.date_of_birth' | translate }} <required></required>
        </label>
        <input type="date" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.birth_date.errors && registerForm.controls.birth_date.touched }"
          formControlName="birth_date" id="birth_date">
        <div id="birth_date" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
    </div>

    <hr>
    <br>

    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="role" class="lbl__thinner">{{'register.office' | translate }} <required></required></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.role.errors && registerForm.controls.role.touched }"
          formControlName="role" id="role">
        <div id="role" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="corporative_telephone" class="lbl__thinner">{{ 'register.corporate_phone' | translate }} <required>
          </required></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.corporative_telephone.errors && registerForm.controls.corporative_telephone.touched }"
          formControlName="corporative_telephone" id="corporative_telephone">
        <div id="corporative_telephone" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="corporative_cellphone" class="lbl__thinner">{{'register.corporate_cell_phone' | translate }}
          <required></required>
        </label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.corporative_cellphone.errors && registerForm.controls.corporative_cellphone.touched }"
          formControlName="corporative_cellphone" id="corporative_cellphone">
        <div id="corporative_cellphone" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="company_category" class="lbl__thinner">{{ 'register.company_category' | translate }} <required>
          </required></label>
        <select
          [ngClass]="{ 'is-invalid': registerForm.controls.company_category.errors && registerForm.controls.company_category.touched }"
          formControlName="company_category" id="company_category" class="form-control form__input">
          <option selected translate>generic.search.select</option>
          <option *ngFor="let category of categories, let i = index" [value]="categories[i].id">
            {{categories[i].description}}</option>
        </select>
        <div id="company_category" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="company_name" class="lbl__thinner">{{'register.company_name' | translate }} <required></required>
        </label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.company_name.errors && registerForm.controls.company_name.touched }"
          formControlName="company_name" id="company_name">
        <div id="company_name" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="zip_code" class="lbl__thinner">{{ 'register.zip_code' | translate }} <required></required></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.zip_code.errors && registerForm.controls.zip_code.touched }"
          formControlName="zip_code" id="zip_code">
        <div id="zip_code" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6 col-lg-4">
        <label for="company_address" class="lbl__thinner">{{'register.company_adress' | translate }} <required>
          </required></label>
        <textarea class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.company_address.errors && registerForm.controls.company_address.touched }"
          formControlName="company_address" id="company_address" rows="5"></textarea>
        <div id="company_address" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="city" class="lbl__thinner">{{'register.city' | translate}} <span style="color: red">*</span></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.city.errors && registerForm.controls.city.touched }"
          formControlName="city" id="city">
        <div id="city" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="corporative_email" class="lbl__thinner">{{'register.corporate_email' | translate }} <required>
          </required></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.corporative_email.errors && registerForm.controls.corporative_email.touched }"
          formControlName="corporative_email" id="corporative_email">
        <div id="corporative_email" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="country" class="lbl__thinner">{{'register.country' | translate }} <required></required></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.country.errors && registerForm.controls.country.touched }"
          formControlName="country" id="country">
        <div id="country" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="company_website" class="lbl__thinner">{{'register.company_website' | translate }} <required>
          </required></label>
        <input type="text" class="form-control form__input"
          [ngClass]="{ 'is-invalid': registerForm.controls.company_website.errors && registerForm.controls.company_website.touched }"
          formControlName="company_website" id="company_website">
        <div id="company_website" class="invalid-feedback" translate>
          register.required_field
        </div>
      </div>
    </div>

    <div *ngIf="identifiedSystems !== null">
      <h5 class="subtitle__register" translate>
        register.identified_systems
      </h5>
      <h6 *ngFor="let identifiedSystem of identifiedSystems">{{ identifiedSystem.description }}</h6>
    </div>
    <hr>
    <br>

    <h6 class="subtitle__register" translate>register.additional_information</h6>
    <br>
    <div class="row">
      <div class="col-sm-5">
        <div class="card">
          <div class="card-body">
            <p class="card-title subtitle__register" translate>register.reference_information</p>
            <div class="form-group col-md-12">
              <label for="name_complement" class="lbl__thinner" translate>register.full_name <required *ngIf="isDataRequired"></required> </label>
              <input [ngClass]="{ 'is-invalid': registerForm.controls.name_complement.errors && registerForm.controls.name_complement.touched && isDataRequired }" type="text" class="form-control form__input" formControlName="name_complement"
                id="name_complement">
                <div id="country" class="invalid-feedback" *ngIf="isDataRequired" translate>
                  register.required_field
                </div>
            </div>
            <div class="form-group col-md-12">
              <label for="corporative_email_complement" class="lbl__thinner" translate>register.corporate_email <required *ngIf="isDataRequired"></required></label>
              <input [ngClass]="{ 'is-invalid': registerForm.controls.corporative_email_complement.errors && registerForm.controls.corporative_email_complement.touched && isDataRequired }" type="text" class="form-control form__input" formControlName="corporative_email_complement"
                id="corporative_email_complement">
                <div id="country" class="invalid-feedback" *ngIf="isDataRequired" translate>
                  register.required_field
                </div>
            </div>
            <div class="form-group col-md-12">
              <label for="corporative_telephone_complement" class="lbl__thinner"
                translate="">register.corporate_phone <required *ngIf="isDataRequired"></required></label>
              <input [ngClass]="{ 'is-invalid': registerForm.controls.corporative_telephone_complement.errors && registerForm.controls.corporative_telephone_complement.touched && isDataRequired }" type="text" class="form-control form__input" formControlName="corporative_telephone_complement"
                id="corporative_telephone_complement">
                <div id="country" class="invalid-feedback" *ngIf="isDataRequired" translate>
                  register.required_field
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-5 offset-2">
        <div class="card">
          <div class="card-body">
            <p class="card-title subtitle__register" translate>register.letter_of_employment_in_the_company <required *ngIf="isFileRequired"></required></p>
            <div class="custom-file mb-12">
              <input [ngClass]="{ 'is-invalid': isFileRequired }" style="display: none" type="file" (change)="onFileSelected($event)" #fileInput>
              <label>{{ fileName }}</label>
              <button class="btn btn__primary" (click)="fileInput.click()" translate
                *ngIf="showBtn">register.add</button>
              <button class="btn btn__danger" (click)="removeFile()" translate *ngIf="!showBtn">REGISTER.REMOVE</button>
            </div>
            <br>
            <br>
            <small id="company_bond_file_input" class="form-text text-muted">
              {{'register.the_file_formats_accepted_by_the_system_are_as_follows' | translate}}: *.pdf *.jpg, *.png,
              *.jpeg
            </small>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="register__buttons">
      <button type="submit" class="btn btn-secondary col-md-1" [routerLink]="['/']"
        translate>generic.buttons.cancel</button>
      <button type="submit" class="btn btn-primary col-md-1" (click)="confirm()" [disabled]="!registerForm.valid"
        translate>generic.buttons.save</button>
    </div>
  </form>
  <hr>
  <br />
  <div *ngIf="isRequestesAdditionalData">
    <rastreabilidade-user-history [requestId]="id"></rastreabilidade-user-history>
  </div>

  <br />
</div>