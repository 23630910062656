import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rastreabilidade-tooltip-total',
    templateUrl: './tooltip-total.component.html',
    styleUrls: ['./tooltip-total.component.scss']
})
export class TooltipTotalComponent implements OnInit {

    @Input() isList: boolean = false;
    @Input() text: any = null;
    @Input() value: any = null;

    constructor() { }

    ngOnInit(): void {
    }

}
