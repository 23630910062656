import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService } from 'src/app/core/services/monitoring.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { FormBuilder } from '@angular/forms';
import * as MonitoringModels from 'src/app/shared/models/monitoring';
import { AlertModalService } from 'src/app/core/services/alert-modal.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'rastreabilidade-home-monitoring',
  templateUrl: './home-monitoring.component.html',
  styleUrls: ['./home-monitoring.component.scss']
})
export class HomeMonitoringComponent implements OnInit {
  
  filterForm = this.fb.group({
    unitMeasureId: [null],
    actionsItemsId: [null],
    notificationMessage: [''],
  });

  maximumQuantityForFilters = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  actionsForFilters = [];
  unitMeasuresForFilters = [];

  monitoringItems: MonitoringModels.Monitoring[] = [];

  constructor(private fb: FormBuilder,
    private translate: TranslateService,
    private monitoringService: MonitoringService,
    public toast: ToastService,
    private alertService: AlertModalService) {}

  ngOnInit() {
    this.all();
    this.getActionsForFilters();
    this.getUnitMeasuresForFilters();
  }

  get f() { 
    return this.filterForm.controls; 
  }
  
  filterHandler() {
    this.all();
  }
  clearFilterHandler(event) {
    this.filterForm.reset();
    this.all();
  }
  
  delete(id: String) {

    this.translate.get(['generic.messages.do_you_want_delete_this_title','generic.messages.do_you_want_delete_this_msg']).subscribe(async msgs => {

      await this.alertService
        .showDynamicModal("danger", msgs['generic.messages.do_you_want_delete_this_title'], msgs['generic.messages.do_you_want_delete_this_msg'])
        .pipe(
          map((confirmed) => {
            if (confirmed) {
              this.monitoringService.delete(id).subscribe(res => {
                if (res?.success) {
                  this.translate.get(['generic.messages.delete_success', 'generic.messages.success_header']).subscribe(msg => {
                    this.toast.showToast(ToastTypeEnum.SUCCESS, msg['generic.messages.success_header'], res?.data || msg['generic.messages.delete_success']);
                    this.all();
                  });
                } else {
                  this.translate.get(['generic.messages.delete_success', 'generic.messages.success_header']).subscribe(msg => {
                    let errorMessage = msg['generic.messages.save_error'];
                    if (res?.errors) {
                      if (res?.errors?.length > 0) {
                        if (Array.isArray(res.errors)) {
                          errorMessage = res?.errors.join('\n');
                        } else {
                          errorMessage = res?.errors;
                        }
                      } else if (Object.keys(res?.data).length > 0) {
                        if (Array.isArray(res.data)) {
                          errorMessage = res.data.join('\n');
                        } else {
                          errorMessage = res.data;
                        }
                      }
                    }
                    this.toast.showToast(ToastTypeEnum.DANGER, msg['generic.messages.error_header'], errorMessage);
                  });
                }
              });
            }
          })
        )
        .toPromise();

    });

  }
  
  private all() {
    const filters =  this.getFilters();
    this.monitoringService.all(filters).subscribe(res => {
      if (res?.success) {
        this.monitoringItems = res.data;
      }
    });
  }

  private getFilters() {
    let data = this.filterForm.value;
    Object.entries(data).forEach(([k, v]) => {
      let delK = ['', 'null'].indexOf(String(v)) >= 0;
      if (!delK &&k === 'notificationMessage') {
        data['notificationMessagePt'] = String(v).trim();
        data['notificationMessageEn'] = String(v).trim();
        delete data[k];
      }
      if (delK) {
        delete data[k];
      }
    })
    return data;
  }

  private getActionsForFilters() {
    this.monitoringService.getActions().subscribe(res => {
      if (res?.success) {
        this.actionsForFilters = res.data;
      }
    });
  }
  
  private getUnitMeasuresForFilters() {
    this.monitoringService.getUnitMeasures().subscribe(res => {
      if (res?.success) {
        this.unitMeasuresForFilters = res.data;
      }
    });
  }
}
