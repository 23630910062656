import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

export interface ModalRequestAccessConfirmation {
  success: boolean;
  reasonFailure: string;
}

@Component({
  selector: 'rastreabilidade-modal-disapprove-reason',
  templateUrl: './modal-disapprove-reason.component.html',
  styleUrls: ['./modal-disapprove-reason.component.scss']
})
export class ModalDisapproveReasonComponent implements OnInit {

  @Input() title: string = '';
  @Input() okTxt: string = 'Enviar';
  @Input() cancelTxt: string = 'Cancelar';
  @Input() type: string = 'success';


  confirmResult: Subject<ModalRequestAccessConfirmation>;

  reasonFailure: string;

  constructor(private bsModalRef: BsModalRef, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();

    this.translateService.get('generic.buttons.send').subscribe(msg => {
      this.okTxt = msg;
    })
  }

  onConfirm() {
    this.confirmAndClose(true, this.reasonFailure)
  }

  onClose() {
    this.confirmAndClose(false, null)
  }

  private confirmAndClose(value: boolean, reasonFailure: string) {
    this.confirmResult.next({ success: value, reasonFailure });
    this.bsModalRef.hide();
  }

}
