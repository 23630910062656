import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { TranslateConfigService } from '../../services/translate-config.service';

@Component({
  selector: 'rastreabilidade-user-info-header',
  templateUrl: './user-info-header.component.html',
  styleUrls: ['./user-info-header.component.scss']
})
export class UserInfoHeaderComponent implements OnInit {

  constructor(public service: HeaderService, public translateService: TranslateConfigService) { }

  ngOnInit(): void {
    this.service.carregarDadosUsuario();
  }

  changeLang(type: string) {
    this.translateService.changeLanguage(type)
  }

}
