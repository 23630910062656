<card>
    <thead>
        <card-header>
            <div class="container ajust__title">
                <div class="row d-flex justify-content-between">
                    <div translate>
                        <fa-icon class="arrow__left col-md-3" [icon]="['fas', 'arrow-left']" [routerLink]="['../..']"></fa-icon>
                        burden_requests.view_berden_request_title
                    </div>
                    <p class="subtitle">
                        <span translate>burden_requests.burden_request_code</span> - <strong> {{burden?.code}}</strong>
                    </p>

                    <p class="subtitle">
                        <span translate>burden_requests.general_status</span>:
                        <fa-icon [icon]="['fas', 'circle']" [class]="getColorClassGeneralStatus(burden?.generalStatus)">
                        </fa-icon>
                        <strong>
                            {{ burden?.generalStatus }}
                        </strong>
                    </p>
                </div>
            </div>
        </card-header>

        <div>
            <alert type="secondary" [hasIcon]="false">
                <ul>
                    <li><span>{{ burden?.total }}</span> Total</li>
                    <li><span>{{ burden?.approvedQuantity }}</span> {{'burden_requests.messages.approved_burdens'|
                        translate}}</li>
                    <li><span>{{ burden?.disapprovedQuantity }}</span> {{'burden_requests.messages.disapproved_burdens'|
                        translate}}</li>
                    <li><span>{{ burden?.pendingQuantity }}</span> {{'burden_requests.messages.pending_burdens'|
                        translate}}</li>
                    <li><span>{{ burden?.noSbrHvi?.quantityBurden || 0 }}</span> {{'burden_requests.messages.no_sbrhvi'|
                        translate}}</li>
                    <li><span>{{ burden?.invalidBurdens || 0 }}</span> {{'burden_requests.messages.invalid_codes'|
                        translate}}</li>
                </ul>
            </alert>

            <div class="container" *ngIf="burden && burden.code && burden.burdens">
                <div *ngIf="burden?.burdens.length!=0">
                    <table class="table no-header">
                        <thead>
                            <tr>
                                <th>
                                    <p class="text-center">
                                        <input type="checkbox" (change)="onSelectAll($event, burden)">
                                    </p>
                                </th>
                                <th translate>burden_requests.view_request.quantity_burdens</th>
                                <th translate>burden_requests.view_request.producer_trading</th>
                                <th translate>burden_requests.view_request.date_opinion</th>
                                <th translate>burden_requests.view_request.status</th>
                                <th translate>burden_requests.view_request.file_generated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let burden of burden?.burdens">
                                <ng-container *ngIf="burden.trading">
                                    <tr>
                                        <td>
                                            <p class="text-center"><input type="checkbox"
                                                    [value]="burden.trading.tradeId"
                                                    [checked]="getBurdensSelected(burden.trading.tradeId)"
                                                    (change)="onCheckboxTradingChange($event, burden)"
                                                    [disabled]="!showDownload(burden?.trading?.status)"></p>
                                        </td>
                                        <td>{{burden.trading.quantityBurden}} </td>
                                        <td>{{burden.trading.name}}</td>
                                        <td>{{burden.trading.dateOpinion | date:'dd/MM/yyyy'}}</td>
                                        <td>
                                            <div class="tooltip-column">
                                                <fa-icon [icon]="['fas', 'circle']"
                                                    [class]="getColorClass(burden.trading.status.key)"></fa-icon>
                                                <rastreabilidade-tooltip-total [text]="burden.trading.tooltip"
                                                    [value]="burden.trading.status.value">
                                                </rastreabilidade-tooltip-total>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row btn__pdf__download d-flex justify-content-center"
                                                *ngIf="showDownload(burden.trading.status)">
                                                <app-download type="cloud-download-alt"
                                                    label="{{ 'burden_requests.view_request.download' | translate }}"
                                                    (onClick)="downloadArquivo(burden.trading.tradeId, true)">
                                                </app-download>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <app-download type="file-pdf"
                                                    label="{{ 'burden_requests.view_request.generate_pdf' | translate }}"
                                                    (onClick)="downloadPDF(burden.trading.tradeId, true)">
                                                </app-download>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngFor="let burdenInternal of burden.burdens">
                                        <td>
                                            <p class="d-flex justify-content-end"><input type="checkbox"
                                                    [value]="burdenInternal.originProducerId"
                                                    [checked]="getBurdensSelected(burden.trading.tradeId+'/'+burdenInternal.originProducerId)"
                                                    [disabled]="getBurdensDisabled(burden.trading.tradeId+'/'+burdenInternal.originProducerId)"
                                                    (change)="onCheckboxBurderIntenalChange($event, burden.trading.tradeId+'/'+burdenInternal.originProducerId)"
                                                    [disabled]="!showDownload(burdenInternal?.status)">
                                            </p>
                                        </td>
                                        <td>{{burdenInternal.quantityBurden}}</td>
                                        <td>{{burdenInternal.producerOrigin}} / {{burdenInternal.producer}}</td>
                                        <td>{{burdenInternal.dateOpinion | date:'dd/MM/yyyy'}}</td>
                                        <td>
                                            <div class="tooltip-column">
                                                <fa-icon [icon]="['fas', 'circle']"
                                                    [class]="getColorClass(burdenInternal.status.key)"></fa-icon>
                                                <rastreabilidade-tooltip-total [text]="burdenInternal.tooltip"
                                                    [value]="burdenInternal.status.value">
                                                </rastreabilidade-tooltip-total>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row btn__pdf__download"
                                                *ngIf="showDownload(burdenInternal.status)">
                                                <app-download type="cloud-download-alt"
                                                    label="{{ 'burden_requests.view_request.download' | translate }}"
                                                    (onClick)="downloadArquivo(burdenInternal.originProducerId, (burden?.trading != null), burden?.trading?.tradeId)">
                                                </app-download>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <app-download type="file-pdf"
                                                    label="{{ 'burden_requests.view_request.generate_pdf' | translate }}"
                                                    (onClick)="downloadPDF(burdenInternal.originProducerId, (burden?.trading != null), burden?.trading?.tradeId)">
                                                </app-download>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>

                                <ng-container *ngIf="!burden.trading">
                                    <tr class="color__table__secundary" *ngFor="let burdenInternal of burden.burdens">
                                        <td>
                                            <p class="text-center"><input type="checkbox"
                                                    [value]="burdenInternal.originProducerId"
                                                    [checked]="getBurdensSelected(burdenInternal.originProducerId)"
                                                    [disabled]="getBurdensDisabled(burdenInternal.originProducerId)"
                                                    (change)="onCheckboxBurderIntenalChange($event, burdenInternal.originProducerId)"
                                                    [disabled]="!showDownload(burdenInternal?.status)"></p>
                                        </td>
                                        <td>{{burdenInternal.quantityBurden}}</td>
                                        <td>{{burdenInternal.producerOrigin}} / {{burdenInternal.producer}}</td>
                                        <td>{{burdenInternal.dateOpinion | date:'dd/MM/yyyy'}}</td>
                                        <td>
                                            <div class="tooltip-column">
                                                <fa-icon [icon]="['fas', 'circle']"
                                                    [class]="getColorClass(burdenInternal.status.key)"></fa-icon>
                                                <rastreabilidade-tooltip-total [text]="burdenInternal.tooltip"
                                                    [value]="burdenInternal.status.value">
                                                </rastreabilidade-tooltip-total>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row btn__pdf__download"
                                                *ngIf="showDownload(burdenInternal?.status)">
                                                <app-download type="cloud-download-alt"
                                                    label="{{ 'burden_requests.view_request.download' | translate }}"
                                                    (onClick)="downloadArquivo(burdenInternal.originProducerId, (burden?.trading != null), burden?.trading?.tradeId)">
                                                </app-download>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <app-download type="file-pdf"
                                                    label="{{ 'burden_requests.view_request.generate_pdf' | translate }}"
                                                    (onClick)="downloadPDF(burdenInternal.originProducerId, (burden?.trading != null), burden?.trading?.tradeId)">
                                                </app-download>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                    <div class="form-row d-flex aling_end">
                        <button class="btn btn-primary" [disabled]="checkIfDisabled()" (click)="downloadArquivoLotes()"
                            translate>burden_requests.view_request.batch_download</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button class="btn btn-outline-primary col-md-3" [disabled]="checkIfDisabled()"
                            (click)="downloadLotesPDF()"
                            translate>burden_requests.view_request.generate_batch_pdf</button>
                    </div>
                </div>

                <div *ngIf="burden?.noSbrHvi">
                    <table class="table no-header">
                        <thead class="color__table">
                            <tr>
                                <th translate>burden_requests.view_request.quantity_burdens</th>
                                <th translate>burden_requests.view_request.status</th>
                                <th translate>burden_requests.view_request.file_generated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{burden.noSbrHvi.quantityBurden}}</td>
                                <td>{{burden.noSbrHvi.status.value}}</td>
                                <td>
                                    <p class="d-flex justify-content-center">
                                        <app-download type="cloud-download-alt"
                                            label="{{ 'burden_requests.view_request.download' | translate }}"
                                            (onClick)="downloadOutrosArquivo(burden.noSbrHvi.status.key)">
                                        </app-download>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            </div>
        </div>
    </thead>
</card>
<br>
<card>
    <thead>
        <div *ngIf="burden?.invalidBurdens">
            <card-header>
                <div class="container ajust__title">
                    <div class="row d-flex justify-content-between" translate>
                        <div translate>
                            burden_requests.view_request.intelligent_tracking
                        </div>
                        <p class="subtitle">
                            <span translate>burden_requests.burden_request_code</span> - <strong>
                                {{burden?.code}}</strong>
                        </p>
                    </div>
                </div>

            </card-header>
            <alert type="info">
                {{'burden_requests.view_request.burden_not_identified_SBRHVI'| translate}}
            </alert>
            <alert type="info">
                {{'burden_requests.view_request.invalid_bale_codes_identified'| translate}}
            </alert>
            <div class="container">
                <table class="table no-header">
                    <thead>
                        <tr>
                            <th translate>burden_requests.view_request.quantity</th>
                            <th translate>burden_requests.view_request.description</th>
                            <th translate>burden_requests.view_request.status</th>
                            <th translate>burden_requests.view_request.file_generated</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{{burden.invalidBurdens}}</th>
                            <th>{{burden.code}}.txt</th>
                            <th translate>burden_requests.view_request.invalidCodes</th>
                            <th>
                                <p class="d-flex justify-content-center">
                                    <app-download type="cloud-download-alt"
                                        label="{{ 'burden_requests.view_request.download' | translate }}"
                                        (onClick)="downloadOutrosArquivo(99)"></app-download>
                                </p>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </thead>
</card>

<router-outlet></router-outlet>
<br>