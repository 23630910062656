import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'rastreabilidade-modal-request-access-response',
  templateUrl: './modal-request-access-response.component.html',
  styleUrls: ['./modal-request-access-response.component.scss']
})
export class ModalRequestAccessResponseComponent implements OnInit {

  @Input() msg: string = '';
  @Input() okTxt: string = 'Ok';
  @Input() type: string = 'success';

  confirmResult: Subject<boolean>;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
  }

  onConfirm() {
    this.confirmAndClose(true)
  }

  onClose() {
    this.confirmAndClose(false)
  }

  private confirmAndClose(value: boolean) {
    this.confirmResult.next(value);
    this.bsModalRef.hide();
  }
}
