import { Component, Input } from '@angular/core';
import { BurdenService } from 'src/app/core/services/burden.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import * as FileSaver from 'file-saver';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rastreabilidade-traceability-certificate',
  templateUrl: './traceability-certificate.component.html',
  styleUrls: ['./traceability-certificate.component.scss'],
})
export class TraceabilityCertificateComponent {
  @Input() code: string;
  @Input() id: string;

  constructor(
    private burdenService: BurdenService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  generateCertificate() {
    this.spinnerService.show();
    this.burdenService.generatePdfCertificateBurden({ id: this.id }).subscribe(
      (res) => {
        if (res.success && res.data) {
          const blob = new Base64ToBlob().convertToBlob(
            res.data,
            'application/pdf'
          );

          const date = new Date();
          let dateFormat: string = '';

          if (this.translate.currentLang === 'pt')
            dateFormat = `${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`;
          else
            dateFormat = `${
              date.getMonth() + 1
            }-${date.getDate()}-${date.getFullYear()}`;

          this.translate
            .get('certificate_configuration.fileName')
            .subscribe((name) => {
              const fileName = dateFormat;
              FileSaver.saveAs(blob, `${this.code}${name}${fileName}`);
            });
        } else {
          this.toastService.showToast(
            ToastTypeEnum.DANGER,
            'Erro',
            'Erro ao baixar o certificado'
          );
        }

        this.spinnerService.hide();
      },
      (err) => {
        this.toastService.showToast(ToastTypeEnum.DANGER, 'Erro', err);
        this.spinnerService.hide();
      }
    );
  }
}
//this.translate.get('burden_requests.burden_non_sbrhvi_msgm').subscribe(msg => msgm = msg);
