import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'rastreabilidade-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private isAbrapa;
  private isSolicitante;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    let roles = this.auth.getRoles();

    if (roles) {
      this.isAbrapa = roles?.filter(x => x === 'abrapa')?.length > 0;
      this.isSolicitante = roles?.filter(x => x === 'solicitante')?.length > 0;

      if (this.isAbrapa)
        this.router.navigate(['gerenciar-pedidos-de-acesso']);
      else
        this.router.navigate(['consulta-fardo-a-fardo'])

    }
  }

  admin() {
    // this.services.get(`TesteSwagger/abrapa`).subscribe(data => {
    //   data
    // })
  }

  convidado() {
    // this.services.get(`TesteSwagger/solicitante`).subscribe(data => {
    //   this.toast.showToast(ToastTypeEnum.SUCCESS, 'Sucesso', data.name)
    // })
  }

}
