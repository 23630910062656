import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AlertModalService } from 'src/app/core/services/alert-modal.service';
import { HttpService } from 'src/app/core/services/http.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ModalRequestAccessConfirmation } from 'src/app/shared/components/modal-request-access/modal-disapprove-reason/modal-disapprove-reason.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AccessRequestStatusEnum } from 'src/app/shared/enums/access-request-status-enum';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'rastreabilidade-edit-access-request',
    templateUrl: './edit-access-request.component.html',
    styleUrls: ['./edit-access-request.component.scss']
})
export class EditAccessRequestComponent implements OnInit {

    statusRequest: 'Aprovado' | 'Pendente' | 'Reprovado' | 'Em análise' = null;
    dateStatusRequest: 'Data Aprovado' | 'Data Reprovado';
    labelDateStatus: string = null;
    labelStatus: string = null;
    requester: any = null;
    requesterWithStatus: any;
    isCompanyBrazilian: boolean = false;
    validateForm: boolean = false;
    id: string = null;
    accessRequestStatus: boolean = false;
    isLoading = true;
    companyCategoryName: string = '';
    @ViewChild('modal') private modal: ModalComponent;
    identifiedSystems: any = [];
    isAprovedOrRepdroved: boolean = true;

    fbRequestInformation = this.fb.group({
        id: [null],
        reference_information: [false],
        bond_letter: [false],
    });

    disableAdditionalInformation = () => !(this.fbRequestInformation.controls.reference_information || this.fbRequestInformation.controls.bond_letter)

    disableApprove = () => (this.statusRequest === 'Aprovado' || this.statusRequest === 'Reprovado')

    disableDisaApprove = () => (this.statusRequest === 'Aprovado' || this.statusRequest === 'Reprovado')

    dataApprovedDisapproved = () => {
        if (this.requester?.status === 'aprovado') {
            this.translateService.get('manage_acccess_request.edit.approval_date')
                .subscribe(event => {
                    console.log('aprovado', this, this.labelDateStatus, event);
                    this.labelDateStatus = event;
                });
        } else {

            this.translateService.get('manage_acccess_request.edit.disapproval_date')
                .subscribe(event => {
                    console.log('reprovado', this, this.labelDateStatus, event);
                    this.labelDateStatus = event;
                });
        }
    }

    statusApprovedRepproved = () => {
        if (this.requester?.status === 'aprovado') {
            this.translateService.get('REPORTS.GENERIC.STATUS.APPROVED')
                .subscribe(event => {
                    console.log('aprovado', this, this.labelStatus, event);
                    this.labelStatus = event;
                });
        } else {
            this.translateService.get('REPORTS.GENERIC.STATUS.DISAPPROVED')
                .subscribe(event => {
                    console.log('reprovado', this, this.labelStatus, event);
                    this.labelStatus = event;
                });
        }
    }

    constructor(
        private services: HttpService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private toast: ToastService,
        private alertService: AlertModalService,
        private spinnerService: SpinnerService,
        private router: Router,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.route.params.subscribe(param => {
            this.id = param["id"];
            this.getRequester(this.id);
            this.requesterWithStatus = this.requester;
        });

        this.fbRequestInformation.controls.id.setValue(this.id);

        this.changeAdditionalValues();

    }

    getRequester(id: string) {
        this.spinnerService.show();
        this.services.getById('Access/request', id).subscribe(res => {
            this.requester = res.data;

            if (this.requester.cnpj) {
                this.isCompanyBrazilian = true;
            }

            this.statusRequest = this.requester?.status;

            this.isAprovedOrRepdroved = (this.requester?.status == 'pendente' || this.requester?.status == 'em análise');

            let locale = res?.data?.isForeign ? 'en' : 'pt-br';
            this.getCategoryById(res?.data?.company_category, locale)

            if (res.data.status === AccessRequestStatusEnum.APPROVED || res.data.status === AccessRequestStatusEnum.DISAPPROVED) {
                this.accessRequestStatus = true
            }

            this.loadIdentifiedSystems(res.data.corporative_email, res.data.cpf_passport)

            this.spinnerService.hide();

            this.dataApprovedDisapproved();
            this.statusApprovedRepproved();
        });
    }

    getCategoryById(id: string, locale: string) {
        this.services.get(`Company/categoryCompany/${id}?locale=${locale}`)
            .subscribe(res => {
                this.companyCategoryName = res?.data?.description
            })
    }

    changeAdditionalValues() {
        this.fbRequestInformation.valueChanges.subscribe(checked => {
            if (checked.reference_information || checked.bond_letter)
                this.validateForm = true;
            else
                this.validateForm = false;
        });
    }

    async submitAdditionalInformation() {
        this.spinnerService.show();
        let form = this.fbRequestInformation.value;
        form.id = this.id;
        this.spinnerService.show();
        this.services.put('Access/requestAdditionalData', form, this.id).subscribe(res => {
            this.getRequester(this.id);
            this.isLoading = false;
            this.fbRequestInformation.controls.bond_letter.setValue(false);
            this.fbRequestInformation.controls.reference_information.setValue(false);
            this.spinnerService.hide();
            this.alertService.showResponseModal("success", res.data);
        }, err => {
            this.translateService.get(`generic.toast.title.error`).subscribe(msg => {
                this.toast.showToast(ToastTypeEnum.DANGER, msg, err);
                this.spinnerService.hide();
            });
        });
    }

    async disapprove() {
        this.translateService.get(
            [
                'manage_acccess_request.edit.registration_disapproval_confirmation',
                'manage_acccess_request.edit.disapprove_this_registration',
                'manage_acccess_request.edit.justification',
                'REPORTS.GENERIC.STATUS.DISAPPROVED',
                'REPORTS.GENERIC.STATUS_REQUEST'
            ]
        ).subscribe(async msg => {
            const registrationDisapprovalConfirmation = msg['manage_acccess_request.edit.registration_disapproval_confirmation'];
            const disapproveThisRegistration = msg['manage_acccess_request.edit.disapprove_this_registration'];
            const justification = msg['manage_acccess_request.edit.justification'];
            const titleHistory = msg['REPORTS.GENERIC.STATUS_REQUEST'];
            const messageHistory = msg['REPORTS.GENERIC.STATUS.DISAPPROVED'];
            this.alertService
                .showDisapproveReasonModal(disapproveThisRegistration)
                .pipe(
                    map((data: ModalRequestAccessConfirmation) => {
                        if (data.success) {
                            this.spinnerService.show();

                            this.services.putWithParams('Access/requestDisapproved', {
                                reason_failure: data.reasonFailure
                            }, this.id).subscribe(async res => {

                                this.saveHistory(this.id, titleHistory, messageHistory);

                                this.isLoading = false;
                                this.spinnerService.hide();
                                await this.alertService.showResponseModal("danger", res.data).pipe(
                                    map((confirmed) => {
                                        if (confirmed) {
                                            this.goToMainManageAccessRequest();
                                        }
                                    })).toPromise();
                            },
                                (err) => {
                                    this.translateService.get(`generic.toast.title.error`).subscribe(msg => {
                                        this.toast.showToast(ToastTypeEnum.DANGER, msg, err);
                                        this.isLoading = false;
                                        this.spinnerService.hide();
                                    });
                                }
                            );
                        }
                    })
                ).toPromise();
        });

    }

    public saveHistory(id: string, titleHistory: string, messageHistory: string) {
        let form = {
            requestId: id,
            title: titleHistory,
            description: messageHistory
        }
        this.services.post(`Historic/SaveHistoryForUser/${this.id}`, form).subscribe();
    }

    async approve() {
        this.translateService.get(
            [
                'MANAGE_ACCCESS_REQUEST.EDIT.MESSAGE_APPROVE',
                'manage_acccess_request.edit.registration_approval_confirmation',
                'REPORTS.GENERIC.STATUS.APPROVED',
                'REPORTS.GENERIC.STATUS_REQUEST'
            ]
        ).subscribe(async msg => {
            const messageApprove = msg['MANAGE_ACCCESS_REQUEST.EDIT.MESSAGE_APPROVE'];
            const registrationApprovalConfirmation = msg['manage_acccess_request.edit.registration_approval_confirmation'];
            const titleHistory = msg['REPORTS.GENERIC.STATUS_REQUEST'];
            const messageHistory = msg['REPORTS.GENERIC.STATUS.APPROVED'];
            await this.alertService
                .showDynamicModal("success", registrationApprovalConfirmation, messageApprove)
                .pipe(
                    map((confirmed) => {
                        if (confirmed) {
                            this.spinnerService.show();

                            this.services.putId('Access/requestApprove', this.id).subscribe(async res => {
                                this.getRequester(this.id);
                                this.isLoading = false;
                                this.spinnerService.hide();

                                //this.saveHistory(this.id, titleHistory, messageHistory);

                                await this.alertService.showResponseModal("success", res.data).pipe(
                                    map((confirmed) => {
                                        if (confirmed) {
                                            this.goToMainManageAccessRequest();
                                        }
                                    })).toPromise();
                            },
                                (err) => {
                                    this.translateService.get(`generic.toast.title.error`).subscribe(msg => {
                                        this.toast.showToast(ToastTypeEnum.DANGER, msg, err);
                                        this.isLoading = false;
                                        this.spinnerService.hide();
                                    });
                                }
                            );
                        }
                    })
                ).toPromise();
        })
    }

    goToMainManageAccessRequest() {
        this.router.navigate(['/gerenciar-pedidos-de-acesso']);
    }

    downloadArquivo() {
        const { company_bond_file, company_bond_file_extension, company_bond_file_name } = this.requester;
        const blob = new Base64ToBlob().convertToBlob(
            company_bond_file,
            company_bond_file_extension
        );
        FileSaver.saveAs(blob, company_bond_file_name);
    }

    loadIdentifiedSystems(email: string, cpf_passport: string) {
        this.services.get(`UserSinda/userSinda/${email}/${cpf_passport}`).subscribe(x => {
            x?.data?.applicationsAccess.map(item => {

                if (!this.identifiedSystems.includes(item)) {
                    this.identifiedSystems.push(item)
                }
            })
            console.log(this.identifiedSystems)
        });
    }

    loadIdentifiedSystemsByUserName(cpf_passport: string) {
        this.services.get(`UserSinda/UserName/${cpf_passport}`).subscribe(x => {
            x?.data?.applicationsAccess.map(item => {

                if (!this.identifiedSystems.includes(item)) {
                    this.identifiedSystems.push(item)
                }
            })
            console.log(this.identifiedSystems)
        });
    }

}
