import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalFrequencyComponent } from 'src/app/modules/reports/producer-trading/modal-frequency/modal-frequency.component';
import { ModalSequenceComponent } from 'src/app/modules/reports/producer-trading/modal-sequence/modal-sequence.component';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { DynamicModalRequestAccessComponent } from 'src/app/shared/components/modal-request-access/dynamic-modal-request-access/dynamic-modal-request-access.component';
import { ModalDisapproveReasonComponent } from 'src/app/shared/components/modal-request-access/modal-disapprove-reason/modal-disapprove-reason.component';
import { ModalRequestAccessResponseComponent } from 'src/app/shared/components/modal-request-access/modal-request-access-response/modal-request-access-response.component';

export enum AlertTypes {
  DANGER = 'danger',
  SUCCESS = 'success'
}

@Injectable({
  providedIn: 'root'
})
export class AlertModalService {
  constructor(private bsModalService: BsModalService) { }

  private showAlert(message: string, type: string) {
    const bsModalRef: BsModalRef = this.bsModalService.show(AlertModalComponent);
    bsModalRef.content.type = type;
    bsModalRef.content.message = message;
  }

  showAlertDanger(message) {
    this.showAlert(message, AlertTypes.DANGER);
  }

  showAlertSuccess(message) {
    this.showAlert(message, AlertTypes.SUCCESS);
  }

  showDynamicModal(
    type?: string,
    title?: string,
    msg?: string,
    okTxt?: string,
    cancelTxt?: string
  ) {
    const bsModalRef: BsModalRef = this.bsModalService.show(
      DynamicModalRequestAccessComponent,
      { backdrop: true, class: 'modal-dialog-centered' }
    );

    if (type) {
      bsModalRef.content.type = type;
    }
    if (title) {
      bsModalRef.content.title = title;
    }
    if (msg) {
      bsModalRef.content.msg = msg;
    }
    if (okTxt) {
      bsModalRef.content.okTxt = okTxt;
    }
    if (cancelTxt) {
      bsModalRef.content.cancelTxt = cancelTxt;
    }

    return (bsModalRef.content as DynamicModalRequestAccessComponent).confirmResult;
  }

  showDisapproveReasonModal(
    title?: string,
    msg?: string,
    okTxt?: string
  ) {
    const bsModalRef: BsModalRef = this.bsModalService.show(
      ModalDisapproveReasonComponent,
      { backdrop: true, class: 'modal-dialog-centered' }
    );

    if (title) {
      bsModalRef.content.title = title;
    }
    if (msg) {
      bsModalRef.content.msg = msg;
    }
    if (okTxt) {
      bsModalRef.content.okTxt = okTxt;
    }

    return (bsModalRef.content as ModalDisapproveReasonComponent).confirmResult;
  }

  showResponseModal(
    type?: string,
    msg?: string,
    okTxt?: string
  ) {
    const bsModalRef: BsModalRef = this.bsModalService.show(
      ModalRequestAccessResponseComponent,
      { backdrop: true, class: 'modal-dialog-centered' }
    );

    if (msg) {
      bsModalRef.content.msg = msg;
    }
    if (okTxt) {
      bsModalRef.content.okTxt = okTxt;
    }
    if (type) {
      bsModalRef.content.type = type;
    }

    return (bsModalRef.content as ModalRequestAccessResponseComponent).confirmResult;
  }

  showFrequencyModal(
    requester?: string,
    request?: string,
    tableFrequency?: any[],
    total?: number
  ) {
    const bsModalRef: BsModalRef = this.bsModalService.show(
      ModalFrequencyComponent,
      { backdrop: true, class: 'modal-dialog-centered' }
    );

    bsModalRef.content.requester = requester;
    bsModalRef.content.request = request;
    bsModalRef.content.tableFrequency = tableFrequency;
    bsModalRef.content.total = total;

    return (bsModalRef.content as ModalFrequencyComponent).confirmResult;
  }

  showSequenceModal(
    requester?: string,
    request?: string,
    tableFrequency?: any[],
    total?: number
  ) {
    const bsModalRef: BsModalRef = this.bsModalService.show(
      ModalSequenceComponent,
      { backdrop: true, class: 'modal-dialog-centered' }
    );

    bsModalRef.content.requester = requester;
    bsModalRef.content.request = request;
    bsModalRef.content.tableFrequency = tableFrequency;
    bsModalRef.content.total = total

    return (bsModalRef.content as ModalSequenceComponent).confirmResult;
  }
}
