<card>

    <card-header>
        <div class="container" translate>header.producer_applicant_report</div>
    </card-header>
    <card-body>

        <div class="container">
            <form [formGroup]="formFilter">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="state_producer" translate>
                            reports.requester.code_request
                        </label>
                        <input type="text" class="form-control" id="code" formControlName="code">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="company__requester" translate>
                            REPORTS.GENERIC.COMPANY_REQUESTER
                        </label>
                        <input type="text" class="form-control" id="company__requester" [ngbTypeahead]="searchCompany"
                            [inputFormatter]="formatterCompany" [resultFormatter]="formatterCompany" (editable)='false'
                            (selectItem)="setValueForm($event, 'companyRequester')" #inputCompany>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="search__type" translate>
                            REPORTS.GENERIC.QUERY_TYPE
                        </label>
                        <select formControlName="requestType" class="form-control" id="search__type">
                            <option value="individual" translate>reports.requester.individual_consultation</option>
                            <option value="bale" translate>reports.requester.batch_query</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="producer" translate>
                            reports.requester.producer
                        </label>
                        <input type="text" class="form-control" id="producer" [ngbTypeahead]="searchProducer"
                            [inputFormatter]="formatterProducer" [resultFormatter]="formatterProducer"
                            (editable)='false' (selectItem)="setValueForm($event, 'producer')" #inputProducer>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="requester" translate>
                            REPORTS.GENERIC.FILTER.REQUESTER_USER
                        </label>
                        <input type="text" class="form-control" id="requester" [ngbTypeahead]="searchRequester"
                            [inputFormatter]="formatterRequester" [resultFormatter]="formatterRequester"
                            (editable)='true' (selectItem)="setValueFormRequester($event, 'requesterId')"
                            #inputRequester>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="resquest__status" translate>
                            REPORTS.GENERIC.STATUS_REQUEST
                        </label>
                        <select class="form-control" formControlName="requestStatus" id="resquest__status">
                            <option value="" translate>reports.requester.all</option>
                            <option value="PENDENTE" translate>REPORTS.GENERIC.STATUS.IN_PROGRESS</option>
                            <option value="AUTORIZADA" translate>REPORTS.GENERIC.STATUS.DONE</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-4">

                        <label for="requester__type" translate>
                            REPORTS.GENERIC.REQUESTER_TYPE
                        </label>
                        <select formControlName="requesterType" class="form-control" id="requester__type">
                            <option value="null" translate>generic.search.select</option>
                            <option value="false" translate>reports.requester.brazilians</option>
                            <option value="true" translate>reports.requester.foreign</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="search__period" translate>
                            REPORTS.GENERIC.PERIOD
                        </label>
                        <div class="input-group">
                            <input type="text" formControlName="initDateInput"
                                placeholder="{{ 'GENERIC.DATE_INPUT_START' | translate }}" class="form-control"
                                bsDatepicker [bsConfig]="bsConfig">
                        </div>
                    </div>
                    <div class="form-group col-md-4 d-flex align-items-end">
                        <div class="input-group">
                            <input type="text" formControlName="finishDateInput"
                                placeholder="{{ 'GENERIC.DATE_INPUT_END' | translate }}" class="form-control"
                                bsDatepicker [bsConfig]="bsConfig">
                        </div>
                    </div>
                </div>
                <div class="row">                   
                    <div class="form-group col-md-4">
                        <label for="user__notified__blocked" translate>
                            reports.requester.notified_blocked_applicant
                        </label>
                        <select formControlName="userNotifiedBlocked" class="form-control" id="user__notified__blocked">
                            <option value="null" translate>generic.search.select</option>
                            <option value="notified" translate>reports.requester.notified_profiles</option>
                            <option value="blocked" translate>reports.requester.blocked_profiles</option>
                        </select>                    
                </div>
                    <div class="form-row d-flex col-md-8" style=" justify-content:end; text-align:end; align-items: flex-end;">
                        <div class="form-group">
                            <button type="submit" class="btn btn-outline-primary col-md-9"
                                (click)="clearFilter($event, inputCompany, inputProducer, inputRequester)"
                                translate>generic.buttons.clean</button>
                        </div>
                        &nbsp;
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary" (click)="refreshPage()"
                                translate>generic.buttons.search</button>
                        </div>
                    </div>
                </div>
            </form>

            <hr>
            <div class="row search__result__labels">
                <label class="range__label">
                    <label *ngIf="formFilter?.controls.initPeriod.value !== ''" translate>{{'generic.search.period' |
                        translate
                        }}:
                        {{
                        formFilter?.controls?.initPeriod?.value | date: 'shortDate' }} </label>
                    <label *ngIf="formFilter?.controls.finishPeriod.value !== ''" translate>&nbsp;{{'generic.search.to'
                        |
                        translate
                        }}
                        {{
                        formFilter?.controls?.finishPeriod?.value | date: 'shortDate' }} </label>
                </label>
                <label class="total__result">
                    {{'generic.search.totalizer' | translate }}: {{ searchedBurden }} {{'generic.search.bales_consulted'
                    |
                    translate }}
                </label>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" translate>reports.requester.requester</th>
                            <th scope="col" translate>REPORTS.REQUESTER.REQUEST</th>
                            <th scope="col" translate>REPORTS.GENERIC.REQUESTER_TYPE</th>
                            <th scope="col" translate>reports.requester.query_type</th>
                            <th scope="col" translate>reports.requester.query_request_date</th>
                            <th scope="col" translate>reports.requester.producer</th>
                            <th scope="col" translate>reports.requester.number_of_burdens_consulted</th>
                            <th scope="col" translate>reports.requester.requester_status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="producersRequesters?.length == 0">
                            <td colspan="11" translate>reports.requester.no_data_found</td>
                        </tr>
                        <tr *ngFor="let producerRequester of producersRequesters">
                            <td>{{ producerRequester?.name }}</td>
                            <td class="details">
                                <a [routerLink]="['detalhes', producerRequester?.requestId]"
                                    *ngIf="producerRequester?.requestId != null; else notDisplay">{{
                                    producerRequester?.code }}</a>
                                <ng-template #notDisplay>
                                    <span>--</span>
                                </ng-template>
                            </td>
                            <td *ngIf="producerRequester?.is_foreign">
                                {{'REPORTS.GENERIC.FOREIGN' | translate}}
                            </td>
                            <td *ngIf="!producerRequester?.is_foreign">
                                {{'REPORTS.GENERIC.BRAZILIAN' | translate}}
                            </td>
                            <td>{{ producerRequester?.request_type }}</td>
                            <td>{{ producerRequester?.date | date: 'shortDate' }}</td>
                            <td>{{ producerRequester?.producer_group }}</td>
                            <td>{{ producerRequester?.burdens }}</td>
                            <td>{{ producerRequester?.status }}</td>
                            <td>
                                <div ngbDropdown placement="left" container="body">
                                    <div id="dropdownButtonActions" ngbDropdownToggle>
                                        <label class='dot-menu__label' for='dot-menu'>
                                            <span>Menu</span>
                                        </label>
                                    </div>
                                    <div id="buttonActions" ngbDropdownMenu aria-labelledby="dropdownButtonActions">
                                        <div class="button-actions">
                                            <div class="item-action" (click)="notifyUser(producerRequester?.email)"
                                                translate>
                                                <fa-icon class="paper-plane-icon" [icon]="['fas', 'paper-plane']">
                                                    ({{producerRequester?.quantityNotify}})
                                                </fa-icon>&nbsp;&nbsp;&nbsp;
                                                reports.requester.notify
                                            </div>
                                            <div class="item-action"
                                                (click)="showFrequency(producerRequester?.producer_type, 
                                                                                            producerRequester?.producer_id, 
                                                                                            producerRequester?.user_id, 
                                                                                            producerRequester?.date, 
                                                                                            producerRequester?.code === 0 ? 'individual' : producerRequester?.code)"
                                                *ngIf="producerRequester?.have_frequency;" translate>
                                                reports.requester.consultation_frequency
                                            </div>
                                            <div class="item-action"
                                                (click)="showSequence(producerRequester?.producer_type, 
                                                                                            producerRequester?.producer_id, 
                                                                                            producerRequester?.user_id, 
                                                                                            producerRequester?.date, 
                                                                                            producerRequester?.code === 0 ? 'individual' : producerRequester?.code)"
                                                *ngIf="producerRequester?.have_sequence;" translate>
                                                reports.requester.query_sequence
                                            </div>
                                            <div class="item-action" translate>
                                                burden_requests.view_request.date_opinion
                                                <span class="text-muted">
                                                    :&nbsp;
                                                    {{ producerRequester?.opinionDate | date: 'shortDate' }}
                                                </span>
                                            </div>
                                            <div class="item-action" (click)="block(producerRequester?.email)"
                                                *ngIf="!producerRequester?.isBlocked; else unlockButton" translate>
                                                <fa-icon class="ban-icon" [icon]="['fas', 'ban']"></fa-icon>
                                                &nbsp;&nbsp;&nbsp;
                                                reports.requester.block
                                            </div>
                                            <ng-template #unlockButton>
                                                <div class="item-action" (click)="unlock(producerRequester?.email)"
                                                    #unlockButton translate>
                                                    reports.requester.unlock
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex flex-column flex-lg-row justify-content-between p-2">
                <p>
                    <label translate>GENERIC.PAGINATION.LABEL.SHOWING</label> <label>&nbsp; {{ currentPageSize
                        }}</label>&nbsp;
                    <label translate>GENERIC.PAGINATION.LABEL.REGISTERS</label> <label>&nbsp; {{ collectionSize
                        }}</label>
                </p>
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [pageSize]="pageSize" (pageChange)="refreshPage()" class="d-flex d-lg-block justify-content-center">
                </ngb-pagination>
                <div class="d-flex justify-content-center">
                    <select class="custom-select" style="width: auto;" [(ngModel)]="pageSize"
                        (ngModelChange)="refreshPage()">
                        <option [ngValue]="10" translate>10 <label
                                translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
                        </option>
                        <option [ngValue]="25" translate>25 <label
                                translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
                        </option>
                        <option [ngValue]="50" translate>50 <label
                                translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
                        </option>
                        <option [ngValue]="100" translate>100 <label
                                translate>GENERIC.PAGINATION.LABEL.ITEMS_PER_PAGE</label>
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-md-12">
                <h6>
                    <app-download [type]="'file-csv'" label="{{ 'generic.buttons.download_csv' | translate }}"
                        [direction]="'row'" (onClick)="downloadArquivoCsv()">
                    </app-download>
                </h6>
            </div>
        </div>
    </card-body>
</card>