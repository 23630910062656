<div class="row m-0 pt-4 mb-3" ngbNav #nav="ngbNav" style="background-color: #f8f8f8">
    <div class="col-xl-3"></div>
    <div class="col-xl-6 row justify-content-center">
        <div class="col">
            <ul class="customed-progressbar pl-4">
                <li ngbNavLink (click)="onClickStep(step)" *ngFor="let step of steps" ngbNavItem
                    [ngClass]="{active: step.active, 'selected-step': activeStepLabel === step.label}">

                    <span class="btn-sm pointer">{{ step.label }}</span>
                    <ng-template ngbNavContent>
                        <ng-content select="card"></ng-content>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
</div>