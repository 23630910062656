import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RespApi } from 'src/app/shared/models/response';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private mainRoute = `Reports`
  private monitoringRoute = `ConfiguringMonitoring`
  private route = {
    getAllProducerRequester: `${this.mainRoute}/reportProducerRequest`,
    downloadCsvProducerRequester: `${this.mainRoute}/reportProducerRequestCsv`,
    downloadFileFrequecyProducer: `${this.mainRoute}/reportRequestProducer/GenerateFileFrequency`,
    downloadFileSequencyProducer: `${this.mainRoute}/reportRequestProducer/GenerateCSVSequency`,

    getAllTradingRequester: `${this.mainRoute}/reportTradingRequest`,
    downloadCsvTradingRequester: `${this.mainRoute}/reportTradingRequestCsv`,
    downloadFileFrequecyTrading: `${this.mainRoute}/reportRequest/GenerateFileFrequency`,
    downloadFileSequencyTrading: `${this.mainRoute}/reportRequest/GenerateCSVSequency`,

    getRequester: `${this.mainRoute}/reportAbrapaFilter/requester`,
    getCompany: `${this.mainRoute}/reportAbrapaFilter/company`,
    getTrade: `${this.mainRoute}/reportAbrapaFilter/trade`,
    blockUser: `${this.monitoringRoute}/blockUser`,
    unlockUser: `${this.monitoringRoute}/unlockUser`,
    notifyUser: `${this.monitoringRoute}/notify`,

    getFrequency: `${this.mainRoute}/producerFrequencyBurden`,
    getSequence: `${this.mainRoute}/producerSequencyBurden`,
    downloadFrequencyCsv: `${this.mainRoute}/producerFrequencyBurdenCsv`,
    downloadSequenceCsv: `${this.mainRoute}/producerSequencyBurdenCsv`,

    getTradingFrequency: `${this.mainRoute}/tradingFrequencyBurden`,
    getTradingSequence: `${this.mainRoute}/tradingSequencyBurden`,
    downloadTradingFrequencyCsv: `${this.mainRoute}/tradingFrequencyBurdenCsv`,
    downloadTradingSequenceCsv: `${this.mainRoute}/tradingSequencyBurdenCsv`
  }

  constructor(private services: HttpService) { }

  getAllProducerRequester(param: string, skip: number, take: number): Observable<RespApi<any>> {
    return this.services.getWithParams(`${this.route.getAllProducerRequester}/${skip}/${take}`, param);
  }

  downloadCsvProducerRequester(param: string): Observable<RespApi<any>> {
    return this.services.getWithParams(`${this.route.downloadCsvProducerRequester}`, param);
  }

  getAllTradingRequester(param: string, skip: number, take: number): Observable<RespApi<any>> {
    return this.services.getWithParams(`${this.route.getAllTradingRequester}/${skip}/${take}`, param);
  }

  downloadCsvTradingRequester(param: string): Observable<RespApi<any>> {
    return this.services.getWithParams(`${this.route.downloadCsvTradingRequester}`, param);
  }

  getRequester(param: string): Observable<RespApi<any>> {
    return this.services.get(`${this.route.getRequester}/${param}`);
  }

  getAssociations(endPoint: string): Observable<RespApi<any>> {
    return this.services.get(`${endPoint}`);
  }

  getCompany(param: string): Observable<RespApi<any>> {
    return this.services.get(`${this.route.getCompany}/${param}`);
  }

  getTrade(param: string): Observable<RespApi<any>> {
    return this.services.get(`${this.route.getTrade}/${param}`);
  }

  blockUser(email: string): Observable<any> {
    return this.services.get(`${this.route.blockUser}/${email}`);
  }

  unlockUser(email: string): Observable<any> {
    return this.services.get(`${this.route.unlockUser}/${email}`);
  }

  notifyUser(email: string): Observable<any> {
    return this.services.post(`${this.route.notifyUser}/${email}`, "");
  }

  generateFileSequencyProducer(param: string): Observable<any> {
    return this.services.get(`${this.route.downloadFileSequencyProducer}/${param}`);
  }

  generateFileFrequencyProducer(param: string): Observable<any> {
    return this.services.get(`${this.route.downloadFileFrequecyProducer}/${param}`);
  }

  generateFileSequencyTrading(param: string): Observable<any> {
    return this.services.get(`${this.route.downloadFileSequencyTrading}/${param}`);
  }

  generateFileFrequencyTrading(param: string): Observable<any> {
    return this.services.get(`${this.route.downloadFileFrequecyTrading}/${param}`);
  }

  /** FREQUENCY, SEQUENCE AND DOWNLOAD OF PRODUCER X REQUESTER **/
  getFrequency(producerGroup: string, producerId: number, userId: string, date: string, code: string): Observable<any> {
    return this.services.get(`${this.route.getFrequency}/${producerGroup}/${producerId}/${userId}/${date}/${code}`);
  }

  getSequence(producerGroup: string, producerId: number, userId: string, date: string, code: string): Observable<any> {
    return this.services.get(`${this.route.getSequence}/${producerGroup}/${producerId}/${userId}/${date}/${code}`);
  }

  downloadFrequencyCsv(producerGroup: string, producerId: number, userId: string, date: string, code: string): Observable<any> {
    return this.services.get(`${this.route.downloadFrequencyCsv}/${producerGroup}/${producerId}/${userId}/${date}/${code}`);
  }

  downloadSequenceCsv(producerGroup: string, producerId: number, userId: string, date: string, code: string): Observable<any> {
    return this.services.get(`${this.route.downloadSequenceCsv}/${producerGroup}/${producerId}/${userId}/${date}/${code}`);
  }

  /** FREQUENCY, SEQUENCE AND DOWNLOAD OF TRADING X REQUESTER **/
  getTradingFrequency(tradingId: string, userId: string, code: number): Observable<any> {
    return this.services.get(`${this.route.getTradingFrequency}/${tradingId}/${userId}/${code}`);
  }

  getTradingSequence(tradingId: string, userId: string, code: number): Observable<any> {
    return this.services.get(`${this.route.getTradingSequence}/${tradingId}/${userId}/${code}`);
  }

  downloadTradingFrequencyCsv(tradingId: string, userId: string, code: number): Observable<any> {
    return this.services.get(`${this.route.downloadTradingFrequencyCsv}/${tradingId}/${userId}/${code}`);
  }

  downloadTradingSequenceCsv(tradingId: string, userId: string, code: number): Observable<any> {
    return this.services.get(`${this.route.downloadTradingSequenceCsv}/${tradingId}/${userId}/${code}`);
  }
}
