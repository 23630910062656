import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AccessRequestStatusEnum } from 'src/app/shared/enums/access-request-status-enum';

@Component({
    selector: 'rastreabilidade-approved-disapproved',
    templateUrl: './approved-disapproved.component.html',
    styleUrls: ['./approved-disapproved.component.scss']
})
export class ApprovedDisapprovedComponent implements OnInit, OnChanges {

    @Input() public accessRequestStatus: boolean = false;
    @Input() public requester;
    labelStatus: string = '';

    constructor() { }
    ngOnChanges(changes: SimpleChanges): void {
        this.loadStatus()
    }

    ngOnInit(): void {
        this.loadStatus()
    }

    loadStatus() {
        if (this.requester != null) {
            if (this.requester.status === AccessRequestStatusEnum.APPROVED)
                this.labelStatus = 'Aprovação'
            else
                this.labelStatus = 'Reprovação'
        }

    }

}
