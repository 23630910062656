import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'rastreabilidade-dynamic-modal-request-access',
  templateUrl: './dynamic-modal-request-access.component.html',
  styleUrls: ['./dynamic-modal-request-access.component.scss']
})
export class DynamicModalRequestAccessComponent implements OnInit {

  @Input() icon: string = 'check-circle';
  @Input() type: string = '';
  @Input() title: string = '';
  @Input() msg: string = '';
  @Input() cancelTxt: string = 'Cancelar';
  @Input() okTxt: string = 'Confirmar';

  confirmResult: Subject<boolean>;

  constructor(private bsModalRef: BsModalRef, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.translateService.get(['generic.buttons.cancel', 'generic.buttons.confirm']).subscribe(msg => {
      this.cancelTxt = msg['generic.buttons.cancel'];
      this.okTxt = msg['generic.buttons.confirm'];
    })
  }

  onConfirm() {
    this.confirmAndClose(true)
  }

  onClose() {
    this.confirmAndClose(false)
  }

  private confirmAndClose(value: boolean) {
    this.confirmResult.next(value);
    this.bsModalRef.hide();
  }
}
