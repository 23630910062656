<div class="back__panel"></div>

<h5 class="system__title"><strong translate>burden_requests.view_berden_request_system_name</strong></h5>

<div class="l-form">
    <div class="card">
        <form action="" class="card-body">

            <!-- <h6 class="form__title">LOGIN</h6>
                <div class="form-group">
                    <label for="username"><strong>Usuário</strong></label>
                    <input type="text" class="form-control input__username" placeholder="Código de identificação">
                </div>
                <div class="form-group">
                    <label for="password"><strong>Senha</strong></label>
                    <input type="password" class="form-control input__password" placeholder="******">
                </div>

            <br>
            <label class="label__forgot" for="">
                <a [routerLink]="['/esqueci-senha']" class="forgot__password__subtitle">Esqueci minha senha</a>
            </label> -->

            <input type="button" [routerLink]="['home']" class="form__button form-btn-sign-in" id="sign-in" value="Acessar">

        </form>
        <div class="card-footer">
            <div>
                <strong class="label_not_registered" for="" translate>manage_acccess_request.edit.not_registered</strong>
                <a [routerLink]="['/pedir-acesso']" class="btn create__account" translate>manage_acccess_request.edit.register</a>
            </div>
        </div>
    </div>
</div>
<rastreabilidade-footer></rastreabilidade-footer>
