<hr>
<p class="p-black" translate>search_by_bale.cotton_origin</p>
<div class="row">
    <div class="col">
        <div class="my-google-map">
            <rastreabilidade-map [latitude]="cottonFarmOrigin?.addressHviDto?.latitude" [longitude]="cottonFarmOrigin?.addressHviDto?.longitude">
            </rastreabilidade-map>
        </div>
    </div>
    <div class="col">

        <div class="row">
            <div class="col">
                <label translate>
                    search_by_bale.fantasy_name
                </label>
                <p>{{ cottonFarmOrigin?.fantasyName }}</p>
            </div>

            <div class="col">
                <label translate>
                    search_by_bale.digit_extension_gs1_plant
                </label>
                <p>{{ cottonFarmOrigin?.extensionDigitGs1 }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label translate>
                    search_by_bale.address
                </label>
                <p>
                    {{ cottonFarmOrigin?.addressHviDto?.description }},
                    {{ cottonFarmOrigin?.addressHviDto?.city }},
                    {{ cottonFarmOrigin?.addressHviDto?.district }}
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <a class="btn btn-outline-primary" target="_blank" href="{{ googleMapsUrl }}" translate>
                    search_by_bale.localization
                </a>
            </div>
        </div>
    </div>
</div>