import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rastreabilidade-laboratory-data',
    templateUrl: './laboratory-data.component.html',
    styleUrls: ['./laboratory-data.component.scss']
})
export class LaboratoryDataComponent implements OnInit {

    public googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query='
    @Input() public laboratoryData: any = null

    constructor() { }

    ngOnInit(): void {
        if (this.laboratoryData !== null) {
            this.googleMapsUrl = `${this.googleMapsUrl}${this.laboratoryData?.addressHviDto.latitude}%2C${this.laboratoryData?.addressHviDto.longitude}`
        }
    }

}
