import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'rastreabilidade-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.hideHeader();
  }

  ngOnDestroy(): void {
    this.commonService.showHeader();
  }

}
