import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RespApi, ResponseWithPaginationApi } from 'src/app/shared/models/response';
import { BurdenRequestResponse } from 'src/app/shared/models/burden/burden-request-response';
import { BurdenRequestViewModel } from 'src/app/shared/models/burden/burden-request-view-model';
import { BurderDownloadRequest, GeneratePdfCertificateBurden } from 'src/app/shared/models/burden/burder-download-request.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BurdenService {
  private route = 'burden';

  constructor(private services: HttpService) { }

  // getBurdenRequest(): Observable<ResponseApi<BurdenRequestResponse[]>> {
  //   return this.httpServices.get(`${this.route}/burdenRequest`);
  // }

  getBurden(id: string): Observable<RespApi<any[]>> {
    return this.services.get(`${this.route}/burden/${id}`);
  }

  getBurdenRequest(page: number, pageSize: number): Observable<ResponseWithPaginationApi<BurdenRequestResponse[]>> {
    return this.services.getWithParams(`${this.route}/burdenRequest`, { page, pageSize });
  }

  getDetailBurdenRequest(id: string): Observable<RespApi<BurdenRequestViewModel>> {
    const lang = localStorage.getItem("locale");
    const language = typeof lang == "string" ? lang : "pt";    
    return this.services.get(`${this.route}/burdenRequest/viewBurdenRequest/${id}?language=${language}`);
  }

  sendBurdenRequest(file: string): Observable<RespApi> {
    return this.services.post(`${this.route}/burdenRequest/`, { file });
  }

  generatePdfCertificateInBatch(data: BurderDownloadRequest): Observable<RespApi<string>> {
    const lang = localStorage.getItem("locale");
    data.language = typeof lang == "string" ? lang : "pt";
    return this.services.post(`${this.route}/burdenRequest/GeneratePdfCertificateInBatch`, data);
  }

  generateTxtCertificateInBatch(data: BurderDownloadRequest): Observable<RespApi<string>> {
    const lang = localStorage.getItem("locale");
    data.language = typeof lang == "string" ? lang : "pt";
    return this.services.post(`${this.route}/burdenRequest/GenerateTxtCertificateInBatch`, data);
  }

  generateTxtCertificateInvalidInBatch(data: BurderDownloadRequest): Observable<RespApi<string>> {
    return this.services.post(`${this.route}/burdenRequest/GenerateTxtCertificateInvalidInBatch`, data);
  }

  generatePdfCertificateBurden(data: GeneratePdfCertificateBurden): Observable<RespApi<string>> {
    const lang = localStorage.getItem("locale");
    data.language = typeof lang == "string" ? lang : "pt";
    return this.services.post(`${this.route}/GeneratePdfCertificateBurden`, data);
  }
  
}
