<div *ngIf="accessRequestStatus">
    <div class="row">
        <div class="col-md-4">
            <label class="lighter__label">Status</label><br>
            <label class="normal__label">{{ requester?.status }}</label>
        </div>
        <div class="col-md-6">
            <label class="lighter__label" translate>manage_acccess_request.edit.reason</label><br>
            <label class="normal__label">
                texto de aprovação/reprovação
            </label>
        </div>

        <div class="col-md-6">
            <label class="lighter__label" translate>manage_acccess_request.edit.veridict_date {{ labelStatus }}</label><br>
            <label class="normal__label">09/06/1985</label>
        </div>
    </div>
</div>
<br>