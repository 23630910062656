import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {
  @Input() type:
    | 'success'
    | 'primary'
    | 'outline-primary'
    | 'secondary'
    | 'danger';

  @Input() disabled: boolean = false;
  @Input() routerLink: string | any[] = null;

  @Output() onClick = new EventEmitter<Event>();

  ngOnInit(): void { }

  click(event: Event) {
    if (!this.routerLink) {
      this.onClick.emit(event);
    }
  }
}
