import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RespApi } from 'src/app/shared/models/response';
import { BurderDownloadRequest } from 'src/app/shared/models/burden/burder-download-request.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class RequesterReportService {
  private route = {
    getAll: `RequesterReport`,
    downloadCsv: `RequesterReport/GenerateCSV`,
    generateTxt: `Burden/burdenRequest/GenerateTxtCertificateInBatch`,
    generatePdf: `Burden/burdenRequest/GeneratePdfCertificateInBatch`
  }    

  constructor(private services: HttpService) { }

  getAll(param: string): Observable<RespApi<any>> {
    return this.services.getWithParams(`${this.route.getAll}`, param);
  }

  downloadArquivoCsv(param: string): Observable<RespApi<any>> {
    return this.services.getWithParams(`${this.route.downloadCsv}`, param);
  }

  generateTxtFile(data: BurderDownloadRequest): Observable<RespApi<string>> {
    const lang = localStorage.getItem("locale");
    data.language = typeof lang == "string" ? lang : "pt";
    return this.services.post(`${this.route.generateTxt}`, data);
  }

  generatePdfFile(data: BurderDownloadRequest): Observable<RespApi<string>> {
    const lang = localStorage.getItem("locale");
    data.language = typeof lang == "string" ? lang : "pt";
    return this.services.post(`${this.route.generatePdf}`, data);
  }

}
