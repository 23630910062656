import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MonitoringService } from 'src/app/core/services/monitoring.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import * as MonitoringModels from 'src/app/shared/models/monitoring';


@Component({
  selector: 'rastreabilidade-edit-monitoring',
  templateUrl: './edit-monitoring.component.html',
  styleUrls: ['./edit-monitoring.component.scss']
})
export class EditMonitoringComponent implements OnInit {

  trans = {
    notificationMessagePt: '',
    notificationMessageEn: '',
  }

  unitMeasuresOptions: MonitoringModels.UnitMeasure[] = []
  actionsOptions: MonitoringModels.Action[] = [];

  dataId: string;
  fSubmited: boolean = false;
  configForm = this.fb.group({
    maximumQuantity: ['', [Validators.required, Validators.min(1)]],
    unitMeasureId: ['', Validators.required],
    frequencyCheck: ['', Validators.required],
    actionsItemsId: ['', Validators.required],
    notifyByEmail: [false],
    notificationMessagePt: [''],
    notificationMessageEn: [''],
  });

  constructor(private fb: FormBuilder,
    private translate: TranslateService,
    private monitoringService: MonitoringService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toast: ToastService) {}

  ngOnInit() {
    this.getMonitoring();
    this.getActionsOptions();
    this.getUnitMeasuresOptions();
    this.getTrans()
  }

  get f() { 
    return this.configForm.controls; 
  }

  onSubmit() {
    this.fSubmited = true;
    
    if (this.configForm.valid) {
      this.monitoringService.update(this.configForm.value, this.dataId).subscribe(res => {
        if (res?.success && res?.data !== null) {
          this.configForm.reset();
          this.fSubmited = false;
          this.translate.get(['generic.messages.save_success', 'generic.messages.success_header']).subscribe(msg => {
            this.toast.showToast(ToastTypeEnum.SUCCESS, msg['generic.messages.success_header'], res?.data || msg['generic.messages.save_success']);
            this.router.navigate(['/configuracao-monitoramento']);
          });
        } else {
          this.translate.get(['generic.messages.save_error', 'generic.messages.error_header']).subscribe(msg => {
            let errorMessage = msg['generic.messages.save_error'];
            if (res?.errors) {
              if (res?.errors?.length > 0) {
                if (Array.isArray(res.errors)) {
                  errorMessage = res?.errors.join('\n');
                } else {
                  errorMessage = res?.errors;
                }
              } else if (Object.keys(res?.data).length > 0) {
                if (Array.isArray(res.data)) {
                  errorMessage = res.data.join('\n');
                } else {
                  errorMessage = res.data;
                }
              }
            }
            this.toast.showToast(ToastTypeEnum.DANGER, msg['generic.messages.error_header'], errorMessage);
          });
        }
      });
    }
  }

  notifyByEmailChangeHandler(event): void {
    this.configForm.get('notificationMessagePt').clearValidators();
    this.configForm.get('notificationMessageEn').clearValidators();
    if (this.configForm.get('notifyByEmail').value) {
      const validations = [Validators.minLength(10), Validators.required]
      this.configForm.get('notificationMessagePt').setValidators(validations);
      this.configForm.get('notificationMessageEn').setValidators(validations);
    } else {
      this.configForm.get('notificationMessagePt').setValue('');
      this.configForm.get('notificationMessageEn').setValue('');
    }
    this.configForm.get('notificationMessagePt').updateValueAndValidity();
    this.configForm.get('notificationMessageEn').updateValueAndValidity();
  }

  private getMonitoring(): void {
    this.activeRoute.paramMap.subscribe( params => {
      this.dataId = params.get('id');
      this.monitoringService.get(this.dataId).subscribe(res => {
        if (res === null || Object.keys(res).length === 0) {
          this.translate.get(['generic.messages.not_found', 'generic.messages.not_found_header']).subscribe(msg => {
            this.toast.showToast(ToastTypeEnum.DANGER, msg['generic.messages.not_found_header'], msg['generic.messages.not_found']);
            this.router.navigate(['/configuracao-monitoramento']);
          });
        }
        this.configForm.setValue(res);
      });
    })
  }

  private getTrans() {
    this.translate.get(['monitoring.form.labels.notification_message_pt', 'monitoring.form.labels.notification_message_en']).subscribe(msgs => {
      this.trans.notificationMessagePt = msgs['monitoring.form.labels.notification_message_pt'].toLowerCase()
      this.trans.notificationMessageEn = msgs['monitoring.form.labels.notification_message_en'].toLowerCase()
    })
  }

  private getActionsOptions(): void {
    this.monitoringService.getActions().subscribe(res => {
      if (res?.success) {
        this.actionsOptions = res.data;
      }
    });
  }
  
  private getUnitMeasuresOptions(): void {
    this.monitoringService.getUnitMeasures().subscribe(res => {
      if (res?.success) {
        this.unitMeasuresOptions = res.data;
      }
    });
  }
}
